import React, { useState, useEffect } from 'react';
import Parse from 'parse';
import '../../assets/css/shop.css'; // Assicurati di creare e importare questo file CSS

const Shopss = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [faqOpen, setFaqOpen] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      const ShopCard = Parse.Object.extend('ShopCard');
      const query = new Parse.Query(ShopCard);
      const results = await query.find();
      const fetchedProducts = results.map(product => ({
        title: product.get('title'),
        text: product.get('text'),
        imageUrl: product.get('imageUrl'),
        price: product.get('price'),
        sellerName: product.get('sellerName'),
        paymentLink: product.get('paymentLink'),
        reviews: product.get('reviews')
      }));
      setProducts(fetchedProducts);
    };

    fetchProducts();
  }, []);

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const toggleFaq = (index) => {
    setFaqOpen(prevFaqOpen => ({
      ...prevFaqOpen,
      [index]: !prevFaqOpen[index]
    }));
  };

  return (
    <div className={darkMode ? 'shopmaglie-container dark-mode' : 'shopmaglie-container'}>
      <div className="shopmaglie-header">
        <h1 className="shopmaglie-title">Lo store online di NGL Cila</h1>
        <p className="shopmaglie-subtitle">Venduto da noi e da altri utenti, con cura e passione</p>
        <input type="text" placeholder="Cerca prodotti..." className="shopmaglie-search" />
        <button onClick={() => setDarkMode(!darkMode)} className="shopmaglie-toggle-mode">
          {darkMode ? 'Modalità Chiara' : 'Modalità Scura'}
        </button>
      </div>

      <div className="shopmaglie-products">
        {products.map((product, index) => (
          <div
            key={index}
            className="shopmaglie-product"
            onClick={() => handleShowModal(product)}
          >
            <img src={product.imageUrl} alt={product.title} className="shopmaglie-product-image" />
            <div className="shopmaglie-product-info">
              <h2 className="shopmaglie-product-title">{product.title}</h2>
              <p className="shopmaglie-product-text" dangerouslySetInnerHTML={{ __html: product.text }}></p>
              <p className="shopmaglie-product-price">{product.price}</p>
              <p className="shopmaglie-product-seller"><em>Venduto da: {product.sellerName}</em></p>
              <div className="shopmaglie-product-reviews">
                {product.reviews && product.reviews.map((review, i) => (
                  <p key={i}><strong>{review.user}:</strong> {review.comment}</p>
                ))}
              </div>
              <button className="shopmaglie-add-to-cart">Aggiungi al carrello</button>
            </div>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div className="shopmaglie-modal" onClick={handleCloseModal}>
          <div className="shopmaglie-modal-content" onClick={e => e.stopPropagation()}>
            <button className="shopmaglie-close-button" onClick={handleCloseModal}>X</button>
            <img src={selectedProduct.imageUrl} alt={selectedProduct.title} className="shopmaglie-modal-image" />
            <h2 className="shopmaglie-modal-title">{selectedProduct.title}</h2>
            <p className="shopmaglie-modal-text" dangerouslySetInnerHTML={{ __html: selectedProduct.text }}></p>
            <p className="shopmaglie-modal-price">{selectedProduct.price}</p>
            <p className="shopmaglie-modal-seller"><em>Venduto da: {selectedProduct.sellerName}</em></p>
            {selectedProduct.paymentLink && (
              <a href={selectedProduct.paymentLink} target="_blank" rel="noopener noreferrer">Clicca per ordinare</a>
            )}
          </div>
        </div>
      )}

      <div className="shopmaglie-faq-section">
        <h2>Domande Frequenti</h2>
        {[
          {
            question: "Come posso effettuare un ordine?",
            answer: "Per effettuare un ordine, seleziona il prodotto desiderato, aggiungilo al carrello e procedi al pagamento tramite il link fornito."
          },
          {
            question: "Quali metodi di pagamento accettate?",
            answer: "Accettiamo pagamenti tramite carta di credito, PayPal e bonifico bancario."
          },
          {
            question: "Come posso contattare il venditore?",
            answer: "Puoi contattare il venditore tramite il link fornito nella pagina del prodotto o inviare un messaggio tramite il nostro sistema di messaggistica."
          },
          {
            question: "Qual è la politica di reso?",
            answer: "Accettiamo resi entro 30 giorni dall'acquisto, purché il prodotto sia nelle stesse condizioni in cui è stato ricevuto."
          }
        ].map((faq, index) => (
          <div key={index} className="shopmaglie-faq-item">
            <button className="shopmaglie-faq-question" onClick={() => toggleFaq(index)}>
              {faq.question}
            </button>
            {faqOpen[index] && (
              <div className="shopmaglie-faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shopss;












