// src/components/Photos.jsx

import React, { useState, useEffect } from "react";
import { fetchPhotos } from '../logic/photoutils';
import '../../assets/css/galleria-photo.css'; // Assicurati di importare il file CSS

const Photos = () => {
  const [photos, setPhotos] = useState([]);
  const [compressedPhotos, setCompressedPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPhotos = async () => {
      try {
        const fetchedPhotos = await fetchPhotos(); // Ottiene le foto da photoUtils.js
        setPhotos(fetchedPhotos);

        // Comprimi le immagini
        const compressedImages = await compressImages(fetchedPhotos);
        setCompressedPhotos(compressedImages);
      } catch (error) {
        console.error("Errore durante il caricamento delle foto:", error);
      } finally {
        setLoading(false);
      }
    };

    loadPhotos();
  }, []);

  const compressImages = async (photos) => {
    const promises = photos.map(photo => compressImage(photo.get("imageUrl")));
    const compressedImages = await Promise.all(promises);
    return compressedImages;
  };

  const compressImage = (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Necessario per evitare problemi di CORS
      img.onload = function() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Imposta la dimensione massima desiderata
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        // Calcola le nuove dimensioni mantenendo il rapporto d'aspetto
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        // Disegna l'immagine ridimensionata nel canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Converti il canvas in un data URL compresso
        const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7); // La qualità può essere regolata
        resolve(compressedDataUrl);
      };
      img.onerror = function() {
        // In caso di errore, restituisci l'URL originale
        resolve(imageUrl);
      };
      img.src = imageUrl;
    });
  };

  const openModal = (photo) => {
    setSelectedPhoto(photo);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <div className="galleria-photo-container">
      <h2 className="galleria-photo-title">
        LA GALLERIA DI NGL CILA
      </h2>
      {loading ? (
        <div className="galleria-photo-loading">
          <div className="galleria-photo-spinner"></div>
        </div>
      ) : (
        <div className="galleria-photo-grid">
          {photos.map((photo, index) => (
            <div
              key={photo.id}
              className="galleria-photo-item"
              onClick={() => openModal(photo)}
            >
              {compressedPhotos[index] ? (
                <img
                  src={compressedPhotos[index]}
                  alt="Photo"
                  className="galleria-photo-image"
                />
              ) : (
                <div className="galleria-photo-placeholder">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {isOpen && selectedPhoto && (
        <div
          className="galleria-photo-modal-overlay"
          onClick={closeModal}
        >
          <div
            className="galleria-photo-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="galleria-photo-close-button"
              onClick={closeModal}
            >
              ×
            </button>
            <h3 className="galleria-photo-modal-title">
              {selectedPhoto.get("title") || "Foto"}
            </h3>
            <img
              src={selectedPhoto.get("imageUrl")}
              alt="Photo"
              className="galleria-photo-modal-image"
            />
            <p className="galleria-photo-modal-description">
              {selectedPhoto.get("description")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Photos;





