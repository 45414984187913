import React, { useState, useEffect } from "react";
import { ref, set } from "firebase/database";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

const colorOptions = [
  { value: "blu scuro", label: "Blu Scuro", color: "#00008B" },
  { value: "rosso", label: "Rosso", color: "#FF0000" },
  { value: "verde", label: "Verde", color: "#008000" },
  { value: "giallo", label: "Giallo", color: "#FFFF00" },
  { value: "arancione", label: "Arancione", color: "#FFA500" },
];

const useAcquistomaglietteLogic = () => {
  const [formData, setFormData] = useState({
    nome: "",
    nomeig: "",
    taglia: "M",
    quantita: "",
    colore: "blu scuro",
    pagamento: "online",
  });
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log("useAcquistomaglietteLogic -> onAuthStateChanged in esecuzione");
    onAuthStateChanged(auth, (currentUser) => {
      console.log("useAcquistomaglietteLogic -> currentUser:", currentUser);
      if (currentUser) {
        setUser(currentUser);
      } else {
        // Costruisce l'URL corrente: pathname + search (per esempio, "/acquisto?step=1")
        const currentUrl = window.location.pathname + window.location.search;
        console.log(
          "useAcquistomaglietteLogic -> Utente non autenticato. currentUrl:",
          currentUrl
        );
        // Salva l'URL corrente nel sessionStorage con la chiave "redirectFrom"
        sessionStorage.setItem("redirectFrom", currentUrl);
        // Costruisce l'URL di login includendo il parametro "from"
        const loginUrl =
          window.location.origin +
          "/login?from=" +
          encodeURIComponent(currentUrl);
        console.log("useAcquistomaglietteLogic -> Redirecting to:", loginUrl);
        // Usa replace per forzare il cambio dell'URL
        alert("Devi aver fatto il login per completare l'acquisto. Clicca su OK per continuare.");
        window.location.replace(loginUrl);
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`handleChange -> ${name}: ${value}`);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      console.log("handleSubmit -> Nessun utente autenticato");
      alert("Devi essere autenticato per completare l'acquisto.");
      return;
    }

    const newPurchaseKey = Date.now(); // Usa timestamp come chiave unica
    console.log(
      "handleSubmit -> Inviando acquisto con chiave:",
      newPurchaseKey,
      "formData:",
      formData,
      "user:",
      user
    );

    try {
      await set(ref(db, `Acquisti/${newPurchaseKey}`), {
        ...formData,
        uid: user.uid,
        email: user.email,
        statoPagamento:
          formData.pagamento === "online" ? "pendente" : "completato",
      });
      alert("Ordine effettuato con successo!");
      console.log("handleSubmit -> Ordine salvato con successo");

      if (formData.pagamento === "online") {
        console.log("handleSubmit -> Reindirizzando a Stripe");
        window.location.href = "https://buy.stripe.com/7sI7sI3V9g2Vby86oq";
      }
    } catch (error) {
      console.error("handleSubmit -> Errore nel salvataggio:", error);
      alert("Errore nel salvataggio dell'acquisto.");
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    colorOptions,
  };
};

export default useAcquistomaglietteLogic;



