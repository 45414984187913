// src/logic/photoUtils.js

import Parse from 'parse';

export const fetchPhotos = async () => {
  const PhotoData = Parse.Object.extend('PhotoData');
  const query = new Parse.Query(PhotoData);

  // Ordina le foto per data di creazione in ordine decrescente (più recente prima)
  query.descending('createdAt');

  try {
    const results = await query.find();
    return results; // Restituisce le foto ottenute
  } catch (error) {
    console.error("Errore durante il fetch delle foto:", error);
    return []; // Restituisce un array vuoto in caso di errore
  }
};


