import React, { useEffect } from "react";
import Navbar from "../components/layout/Navbar";
import Photos from "../components/layout/Photos";
import Footer from "../components/layout/Footer";
import UserAccessData from "../components/logic/userAccessData";


function Galleria() {
     useEffect(() => {
            document.title = 'Foto'; // Imposta il titolo della pagina
          }, []);
    return (
        <div>
            <Navbar />
            <UserAccessData />
            <Photos />
            <Footer />
        </div>
    );
}

export default Galleria;