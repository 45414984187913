import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaUsers,
  FaHandshake,
  FaDigitalTachograph,
  FaPhoneAlt,
} from "react-icons/fa";
import illustration from "../../assets/img/gossipig.png"; // Assicurati che l'immagine esista
import profileImg from "../../assets/img/ngllogo.jpg"; // Assicurati che l'immagine esista

const HomePage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="space-y-16">
      {/* --- Sezione Profilo / Cos'è NGL Cila? --- */}
      <section
        id="profile"
        className="relative py-16 bg-gradient-to-r from-green-400 to-blue-500 overflow-hidden"
      >
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center gap-8">
          {/* Immagine Profilo */}
          <div className="md:w-1/2" data-aos="fade-right">
            <img
              src={profileImg}
              alt="Profilo NGL Cila"
              className="w-64 h-64 md:w-80 md:h-80 object-cover rounded-full shadow-xl mx-auto"
            />
          </div>
          {/* Testo Profilo */}
          <div className="md:w-1/2 text-white" data-aos="fade-left">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Cos'è NGL Cila?
            </h1>
            <p className="text-lg md:text-xl mb-4">
              NGL Cila è un progetto dedicato alla divulgazione di gossip e
              notizie del territorio, contraddistinto da passione e innovazione.
              La nostra missione è quella di creare connessioni autentiche tra i
              giovani, promuovendo collaborazioni che lasciano il segno.
            </p>
            <p className="text-lg md:text-xl">
              Utilizziamo mezzi digitali sofisticati per trasformare il contatto
              tra le persone in una rete vibrante e dinamica. Approfondisci le
              attività e lasciati coinvolgere!
            </p>
          </div>
        </div>
        {/* Elementi Decorativi */}
        <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-20 rounded-full blur-3xl animate-pulse"></div>
        <div className="absolute bottom-0 left-0 w-48 h-48 bg-white opacity-20 rounded-full blur-3xl animate-pulse delay-200"></div>
      </section>

      {/* --- Sezione Cosa Facciamo --- */}
      <section id="services" className="py-16">
        <div className="container mx-auto px-4">
          <h2
            className="text-3xl md:text-4xl font-bold text-center mb-12"
            data-aos="fade-up"
          >
            Cosa Facciamo
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Card 1: Interconnessione */}
            <div
              className="bg-white rounded-lg shadow-lg p-6 relative hover:shadow-2xl transition"
              data-aos="zoom-in"
            >
              <FaUsers className="text-blue-500 text-4xl mb-4" />
              <h3 className="text-xl font-semibold mb-2">Interconnessione</h3>
              <p className="text-gray-600">
                Creiamo legami tra i giovani attraverso eventi, social media e
                piattaforme digitali.
              </p>
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-blue-500 w-8 h-8 rounded-full opacity-20"></div>
            </div>
            {/* Card 2: Collaborazioni */}
            <div
              className="bg-white rounded-lg shadow-lg p-6 relative hover:shadow-2xl transition"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <FaHandshake className="text-green-500 text-4xl mb-4" />
              <h3 className="text-xl font-semibold mb-2">Collaborazioni</h3>
              <p className="text-gray-600">
                Favoriamo sinergie con organizzazioni che condividono la nostra
                passione per l'innovazione.
              </p>
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-green-500 w-8 h-8 rounded-full opacity-20"></div>
            </div>
            {/* Card 3: Mezzi Digitali */}
            <div
              className="bg-white rounded-lg shadow-lg p-6 relative hover:shadow-2xl transition"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <FaDigitalTachograph className="text-purple-500 text-4xl mb-4" />
              <h3 className="text-xl font-semibold mb-2">Mezzi Digitali</h3>
              <p className="text-gray-600">
                Utilizziamo tecnologie digitali avanzate per produrre contenuti
                di alta qualità.
              </p>
              <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-purple-500 w-8 h-8 rounded-full opacity-20"></div>
            </div>
          </div>
        </div>
      </section>

      {/* --- Sezione Collaborazioni --- */}
      <section id="collaborations" className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 text-center">
          <h2
            className="text-3xl md:text-4xl font-bold mb-6"
            data-aos="fade-up"
          >
            Collaborazioni
          </h2>
          <p
            className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Grazie a collaborazioni strategiche, uniamo le forze con realtà del
            territorio per realizzare progetti innovativi e contenuti esclusivi.
            Lavoriamo insieme per portare alla luce il meglio della nostra community.
          </p>
        </div>
      </section>

      {/* --- Sezione Contattaci --- */}
      
    </div>
  );
};

export default HomePage;
