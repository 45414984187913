import React, { useState, useEffect } from 'react';
import LoadingPopup from './LoadingPopup';
import '../../assets/css/notizieFerrovie.css'; // Importiamo il file CSS esterno

const NotizieFerrovie = () => {
  const [notizie, setNotizie] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoadingPopup, setShowLoadingPopup] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const [tickerResponse, rssResponse] = await Promise.all([
          fetch('https://trenitaliapijs-production.up.railway.app/news/ticker'),
          fetch('https://trenitaliapijs-production.up.railway.app/news/rss')
        ]);
        const tickerData = await tickerResponse.json();
        const rssData = await rssResponse.json();
        setNotizie([...tickerData, ...rssData]);
      } catch (error) {
        console.error('Errore nel fetch delle notizie:', error);
        setError('Si è verificato un errore durante il caricamento delle notizie.');
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
    setTimeout(() => {
      setShowLoadingPopup(false);
    }, 3000);
  }, []);

  return (
    <div className="notizie-ferrovie-container">
      {showLoadingPopup && (
        <LoadingPopup duration={3000} onEnd={() => setShowLoadingPopup(false)} />
      )}
      {loading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="notizie-lista">
          {notizie.map((notizia, index) => (
            <div key={index} className="notizia-card">
              <h5 className="notizia-titolo">{notizia.titolo}</h5>
              <p className="notizia-data">
                {new Date(notizia.data).toLocaleString()}
              </p>
              <div
                className="notizia-testo"
                dangerouslySetInnerHTML={{ __html: notizia.testo }}
              ></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotizieFerrovie;



