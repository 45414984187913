import React from "react";
import { FaInstagram } from "react-icons/fa";

// Dati delle collaborazioni
const collaborations = [
  {
    name: "Raimbow Spotted",
    description:
      "Un profilo gossip nuovo che riguarda i gossip della Lomellina.",
    icon: require("../../assets/img/raindowspotted.jpg"),
    instagram:
      "https://www.instagram.com/spotted_rainbow.lomellina/?utm_source=ig_web_button_share_sheet",
  },
  {
    name: "Spotted Caramuel",
    description:
      "Lo spotted principale dell'Istituto Caramuel di Vigevano.",
    icon: require("../../assets/img/spottedcaramuel.jpg"),
    instagram:
      "https://www.instagram.com/spotted.caramuel_/?utm_source=ig_web_button_share_sheet",
  },
  {
    name: "ITIS Caramuel",
    description: "L'istituto tecnico di Vigevano.",
    icon: require("../../assets/img/itislogo.jpg"),
    instagram:
      "https://www.instagram.com/iis_caramuelroncalli?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
  },
  {
    name: "NGL Vige",
    description:
      "Profilo del gruppo NGL Cila, si occupa dei gossip di Vigevano.",
    icon: require("../../assets/img/nglvige.png"),
    instagram:
      "https://www.instagram.com/ngl_vigee?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
  },
  {
    name: "Crazy Lista",
    description:
      "Profilo ufficiale dei rappresentanti dell'ITIS Caramuel di Vigevano.",
    icon: require("../../assets/img/crazylista.jpg"),
    instagram:
      "https://www.instagram.com/crazy.lista?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
  },
];

const CardCollabb = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 p-4">
      {collaborations.map((collab, index) => (
        <div
          key={index}
          className="bg-blue-900 rounded-3xl shadow-lg overflow-hidden hover:shadow-2xl transition transform hover:-translate-y-1 hover:scale-105"
          data-aos="fade-up"
          data-aos-delay={`${index * 150}`}
        >
          <img
            src={collab.icon}
            alt={collab.name}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h3 className="text-2xl font-semibold mb-2 text-white">
              {collab.name}
            </h3>
            <p className="text-gray-300 mb-4">{collab.description}</p>
            <a
              href={collab.instagram}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-semibold rounded-full transition transform hover:scale-105"
            >
              <FaInstagram className="mr-2" /> Visita su Instagram
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardCollabb;


