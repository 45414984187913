import React, { useEffect } from "react";

const FullScreenGame = () => {
  useEffect(() => {
    // Crea ed inserisce lo script esterno
    const script = document.createElement("script");
    script.src = "https://play.gamindo.com/scripts/builder.js";
    script.async = true;
    document.body.appendChild(script);

    // Rimuove lo script in fase di cleanup per evitare duplicazioni
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <iframe
      id="game"
      title="Game"
      src="https://play.gamindo.com?cd=Q0HbO29Q3EL8cH7MXAANANHHADQ0HYEMSPDCOP7M7M7MFIFI.1F8"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
      }}
      allowFullScreen
    />
  );
};

export default FullScreenGame;



