import React from 'react';

const isTrenoSuburbano = (numeroTreno) => {
  const numeroStr = numeroTreno.toString();
  return numeroStr.startsWith('24') || numeroStr.startsWith('25');
};

const TableTreni = ({ treni }) => {
  return (
    <div className="overflow-x-auto mt-8">
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-blue-600 text-white">
          <tr>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Compagnia</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Numero Treno</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Tipologia</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Destinazione</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Partenza</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Ritardo</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Stato</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Binario Prog.</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Binario Reale</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Categoria</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Descrizione</th>
            <th className="py-3 px-5 text-left text-sm font-semibold uppercase">Binario Effettivo</th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {treni.map((treno, index) => {
            let tipologiaTreno = treno.compTipologiaTreno;

            // Logica per treni con 4 cifre
            if (treno.numeroTreno.toString().length === 4) {
              if (treno.codiceCliente === 63) {
                tipologiaTreno = "Regio Express";
              } else {
                tipologiaTreno = "Regionale Veloce";
              }
              if (treno.codiceCliente === 1) {
                tipologiaTreno = "Alta Velocità";
                treno.categoria = "FR";
              }
            }

            if (treno.numeroTreno.toString().length === 3) {
              if (treno.codiceCliente === 63) {
                tipologiaTreno = "Regionale";
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Logomi_r.svg/512px-Logomi_r.svg.png";
              }
              else if (treno.codiceCliente === 1) {
                tipologiaTreno = "Eurocity";
              }
              else {
                tipologiaTreno = "Intercity";
              }
            }
            
            if (treno.numeroTreno.toString().length === 2) {
              if (treno.codiceCliente === 63) {
                tipologiaTreno = "Regionale";
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Logomi_r.svg/512px-Logomi_r.svg.png";
              }
              else {
                tipologiaTreno = "Regionale";
              }
            }

            // Logica per treni con 5 cifre
            else if (treno.numeroTreno.toString().length === 5) {
              if (isTrenoSuburbano(treno.numeroTreno)) {
                // Logica per treni suburbani con 5 cifre che iniziano per 24 o 25
                if (treno.numeroTreno.toString().startsWith('241')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUnt7Y8bGgldXVKiodeTkjmxsXZmZb46Oa7e3e/PzlNXViF5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq7046833OsYAjICBCEqnrpuCkHBcFptg3PfBsoTsk4mMQBRD7FSMnxIz/BGOnKTS96wkiD8aNCONDQ6JwoIQgFV609E2g45pLQtDdZImrTEyxtEQM5RhBncWCTIpO3wjBjSIJDqCFF0jAVA9ihNYIwKPFDJzLAVvEjKhggqdmwUymxOpRZuEMAOrEqajjwcxk7MNPwuCjCOemy8+CIZQMr67rT8Hxyu1MM+ruFOOK8x2uxPEU8odbYnbFNXWKn8kRuOsmD7XGpEAwutNSqQXtusW9TKyGtHa9F0IJ02DAFUCmVDRQDBQQgzdSMyjAAzAu4cUsgXT0C4IRnwxeSbScfPRAsBMGTQCKLmvUAZYJPyxnFBR5oVy4mZKgClRQ0WRWxYwuGfFx4ZkuxAxSDCtQRwf3y6cBNB0jcoSOJSoS4lwVcQ66TgQ1LNqKtiLGNCN2Pro6pShSEJuSxDPR4CoHPJtUyCAAI4bAQjgVQGqMFGdiBNvigAAOw==";
                  tipologiaTreno = "S1";
                } else if (treno.numeroTreno.toString().startsWith('242')) {
                  treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/d/d6/Milano_S2.svg";
                  tipologiaTreno = "S2";
                } else if (treno.numeroTreno.toString().startsWith('245')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUmxsXbe3e5mZb+Tkjnt7Y9XViI6Oa1dXVKiodcbGgvPzlF5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704683xEkUAjAATJEanrpzCkHAMJNoS3Hiu3wNbLS+ZcKQRDI8F30RxHNIyhabw6WNKZZurjKAkDAsogmAQnGmsWtJCKSysLYsE4I05wBiJvH6vV8pjAkoVgIIcMgGFEwYxdIkWXjFcjgN3jhl/MJYNCDCBmhZ2MZYLjJ8WIqWJmAAIpqCHjmUAPa4URjJJgqSdBL2NmrtCCkq3SMOfoUIIxypRWimaUgjQhmkAB8BXuWfWI7SOC5xNCL8VCgEHewGyW5+UUuUccV+mC8lCDIVAQrX2R8w+gsGQ5GofrkT3RlCrxY5IoUUxqPTjVwjSwFoUnGV62A6jhFUOjgWBBOBxQkKShdghKrkpxjYfFkl48hhzBEAWqDaWbFjopEQVJzg0/NkgXoWTrWCSOLBQwjwZSSmQCtB0AoGGc5RoJIEAhzgh5VaBMUBAwYCvMW52ENgtXjcSajucvELuwkhpRjm8s/bNAtYjRGHOjYFgQF4JLqQlOAwzwQEdAwQwtmDAcY7Ik1lq3syZQwQAOw==";
                  tipologiaTreno = "S5";
                } else if (treno.numeroTreno.toString().startsWith('246')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUmxsXbe3e5mZb+Tkjnt7Y9XViI6Oa1dXVKiodcbGgvPzlF5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704683xEkUAjAATJEbHEUbiqprCkHQNJDBlDDON5JZFz0YE5BaJIiAApCiURZzqAL01Jc+qTSXQAhRXQrGAIgh4JOmmADW5FldiAR5M0jXD2iF1pSRrAn0UeWmCFjZMhg2EAAWKFWI1BIpsgI8VfzSKXTVglxRUNYYLNp6fEyI1d02pJAenFaE0iU0GNT+wFJw1jk0INXwKBb8jAQOrTaRFph1ZJEwDUAOGsjYIzBqtI9FaDIZVCHwZyl42033kSr0Y3EoB2pKCC8RKCMgTjMWTE5k0r4btlNxLRwObhF0kcI2qZsNbBWcFM9Aj8WkBw4gUAo5Qc0EjgH2XvITIqQBPIQaII0B+ylfBRiANkWioDEmkZbwYW3I1qKRpAkEA9zDlzNVvBIWYFDfAWwfr4tGhGVzqbDCxEYWfQSegBKoT6QhsNsRdqMr0FCMLhP5dQJgyxwkOjDhKcHrhZ9kNMffUIWJygtcSFv4aycEz4bsAVWlkTXwNjoGLH4+Us5G1geAqBjdABldZQtEqYlV4rHKOw2ciATrD3OzjWA4C8GZlbkIgwYHD7wYIUK3hw+3cCnhPHU68eIcIADs=";
                  tipologiaTreno = "S6";
                } else if (treno.numeroTreno.toString().startsWith('248')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUmxsXbe3e5mZb+Tkjnt7Y9XViFdXVI6Oa/PzlKiodcbGgl5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704681xEkUAjAATKEZXEUqLJmq8MGRtA0on0He5xJoEr9fbCIi2APCyQBJzGZGzxlhSmtObZpgdQZcEYgFFEAyGXwt3FEg1CIiitVeAWRIKgN2St/0qfTVuMYEkAlYTN4cXN2kdN0qIDYUAkRdSJI4bYTYEkg1rf5c2mhqUAJ8NWhlrnjFxNp+cNRkJqzGYJHtgN7sUlAhLsDWWc6QXtja+HEc3BZIHvRXJNaUb1DeihD2DsyTBiMM3B9qPPQELBTfgklMHgxzYU9Yx8nT1WQzLiAnRTgf77uR6kqrCgCwBJxzsAoBdwQYJxN2o4mHNCHVEHD6MiKScBHs4xSacGuGxoJB7Fnr4OmnjwMMLFlFVGKgHmcqX0+RMMHDD1YWFNRbhnICRFoU1xSx48zL01y2IN+AxOtZUgkSZEk5dy1ZVgj8SzyYMTGqBp7KuSwFoG7tBosuuFmc+vdMIyAkOFh3R9Klm7qZvUiGOfOvUBkULRQ0BSTziQIDHX6XlnAhvQeSLQEB2CXiVRIDLJA6r6OwuISiG36wAZTigA00i7yTByXJggOkKlp0wKGmFBYLHwAcIuF3LDPAxC4h3Xc68+ZIIADs=";
                  tipologiaTreno = "S8";
                } else if (treno.numeroTreno.toString().startsWith('249')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUmxsXbe3e5mZb+Tkjnt7Y9XViI6Oa1dXVKiodfPzlMbGgl5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704682xEkUAjAATJEZHKUbiJoQqNwtD3jiQdHU+HopZRmHzGTcGhPG2EFoWS+Muc4jiUk4J1JrTFLmkoJNgLKAIgsF3evneBgTDwHfI+griiiIByFeqOHUrPk58OAJZDQY5ghVbTEI5AYkNSjeTGDkDM2Q4MVmPYRkilzOGN5SWJI0XpyMMM4CoWQo5fhauIzOkN7cqczcIG7kAsZJZvCMFwzjCMgJ3TjlsGAU4mCq1Rk0y0xtuANgqsjkI3BzeGukzVghY6oEaxOcy2lHL8MEZoaJOCqpGEPiakGwEtQn8RjhLBCzKwAbQ1ikquIqSBAXkcsDKZCQAxSsWuidgXEKPQkIwukKuAKcsQ8MlCKzdYKVSAstiGZIE3JHjU02RhJAM8OgRhoRc4n5KkDkrUU+lFYhRYjoiKdSMOJ1E7AW1AkAA+IQkPAi1EySxx7pSYFkoqJMTHFiS1UDg48MNZg+8E0kMwEIOBj72SURVIdGvXC8QQJNg6BIGdzfYQ9nvQuEyFrFyEeil3d4sL8Fs2hAFAaKaBDQ3GxD5og8EetU2IJDgAFGPAwS0XkF0AIrdsoMLH54oAgA7";
                  tipologiaTreno = "S9";
                } else if (treno.numeroTreno.toString().startsWith('243')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUnt7Y8bGgqiodVdXVOTkjo6Oa2xsXfPzlLe3e9XViJmZb15eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704680pSsEBjABzDEqnFsngJoVqCQxp38BwIcbh+wiLwkDAkQxBFaJmbFZ4xqREUGzedByBtYltQJ2TxdbYxWjHOEkhsD1QBmic1FJoBlCsBTOHEI0XFHU2DAYDRE2AGEYBcxMIcI9xCR4jDAJ0RgwYcDeTHAo4YjcxFAekF4I3ijduHak5Da8jMhNVNqg4pxwjXQk3BLQSfiTAFpy3Mp5hrMENeyMBFwZpzRPDvMEIOJcW1yONtDgptNOq0jitzdo34BpnNsoVvjjRzbIAtPM29TtW3DLHKqlQ4K3EhnJGCPzjwMaGAQwtXDSk12EMgXEbnsG6gCaehnVb//hlyIXLSjoltpoQaCcBJIl271RiVCFqC8uYsy4EtPKQ1pcmmnQy21GgqIIBGkl47PBz2wVviVwlxVdtyaILKQEszYhjq4qpHEdlQ1etpRFM5oJpLFZ2IgkL+kawpVWwrISdVCnUHHFShregZRG+reCtjIysfYNlFSlBnDochmndW+hFTjO33+wmRYuMFk4AiRuc4JA08k7ADWZiAEWyQioDqr3gBTB3Auaejflg2EuCsQTMtH9ktdFOY5e9DAYIKDoAuEALLuO8XGWDVHTpqCkIlr4Sw/Uk2+OElsAbTdQLn21LJ0GZTngcBBawnLDzZIH3NyxJNvTDx4IE8z1R1BKA7eTR3x2x2aXgggw26CAFEQAAOw==";
                  tipologiaTreno = "S13";
                } else if (treno.numeroTreno.toString().startsWith('250')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUnt7Y8bGgqiodVdXVPPzlOTkjpmZb2xsXY6Oa7e3e9XViF5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704681pUQECjACDDEunrpzAkHAMDFeRIDhecHaur4WXbDiq2Ia7zFGWbBGfNMlyqEw8VYLntWEIaBGX7rdjIAZQBsVBOCuItICD8a2Vc4aBpmcAKPDhAAoVf3CCG4QkhhsLMgcyBhWMMY4xkBsyYGQwNGUxFp0jnDIcoCSWHaETCjEEF6kSqzCth6MrihKUJJkVtw25I7sZVp4sFXSvG8czHMp9xRQyKXcx0hrDMMEsBUw83BuxMQHPXLUbpUXdRALFiCUd7Qwq1zIE6ypeMAkd+CT6KnAEqmlgg2xgjCgdtsERd6kSKodACADUY8SbBoUwKHb4RUSjhCzE3zaAhDGuwZQh8S60y4ZhZUkp82LYMzapgzI7L00SJMGwgkQYvTD8TJSTwk50FR49hHGqKEaSFc4B0BSyqAR+JCyAGzHrGyurFNpNrcCR5YWyYCfEHEtBGUINbtNKGDqi54RoS0kILCp1phSLSgAXPfqpnAapNt9iOKq4Abx3MVIe7rfXpFgAXSlgBeBvw+bOGTZjzkE3hsedjS+g3vAUUEYMSqcx3bAWEAGPJgXX0E3WNQycF0ZmdVI1g4HarA7gnuCSQ3MVBgbc8HFAwXIjBrJnvz6hgPbtcsOLH09+QgQAOw==";
                  tipologiaTreno = "S11";
                } else if (treno.numeroTreno.toString().startsWith('256')) {
                  treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/8/8e/Milano_S12.svg";
                  tipologiaTreno = "S12";
                } else if (treno.numeroTreno.toString().startsWith('244')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUnt7Y8bGgqiodVdXVOTkjo6Oa2xsXfPzlLe3e9XViJmZb15eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704680pSsEBjABzDEqnFsngJoVqCQxp38BwIcbh+wiLwkDAkQxBFaJmbFZ4xqREUGzedByBtYltQJ2TxdbYxWjHOEkhsD1QBmic1FJoBlCsBTOHEI0XFHU2DAYDRE2AGEYBcxMIcI9xCR4jDAJ0RgwYcDeTHAo4YjcxFAekF4I3ijduHak5Da8jMhNVNqg4pxwjXQk3BLQSfiTAFpy3Mp5hrMENeyMBFwZpzRPDvMEIOJcW1yONtDgptNOq0jitzdo34BpnNsoVvjjRzbIAtPM29TtW3DLHKqlQ4K3EhnJGCPzjwMaGAQwtXDSk12EMgXEbnsG6gCaehnVb//hlyIXLSjoltpoQaCcBJIl271RiVCFqC8uYsy4EtPKQ1pcmmnQy21GgqIIBGkl47PBz2wVviVwlxVdtyaILKQEszYhjq4qpHEdlQ1etpRFM5oJpLFZ2IgkL+kawpVWwrISdVCnUHHFShregZRG+reCtjIysfYNlFSlBnDochmndW+hFTjO33+wmRYuMFk4AiRuc4JA08k7ADWZiAEWyQioDqr3gBTB3Auaejflg2EuCsQTMtH9ktdFOY5e9DAYIKDoAuEALLuO8XGWDVHTpqCkIlr4Sw/Uk2+OElsAbTdQLn21LJ0GZTngcBBawnLDzZIH3NyxJNvTDx4IE8z1R1BKA7eTR3x2x2aXgggw26CAFEQAAOw==";
                  tipologiaTreno = "S13";
                } 
                else if (treno.numeroTreno.toString().startsWith('247')) {
                  treno.tipologiaTrenoIcona = "data:image/gif;base64,R0lGODlhRAArALMAAFJSUmxsXbe3e5mZb+Tkjnt7Y8bGgo6Oa1dXVKiodfPzlNXViF5eV/Pz8wAAAAAAACH5BAEHAA0ALAAAAABEACsAAAT/sMlJq704682pEkUAjAATJEunrpzBkHAMJNYQ3Hiu74HBei+ZcGQZGmWCn8RwFNI8zShByYzGLAvrUUkYFlAEwSA4syS0w4BSWFBcFGd3hZCo2+/4g0y+OseSSiwFMU8sMmqBK1kwCFwyU4kqCDEpP34wkSoDMQWBeleZG10xfCwipKEaZCOFP58wiKlmMQyRAmyyFQqPkbtDPrkNpyQDoa8yCMCht6ChTQiVkTLKmb5HnYmDsMEKk0cIpSqjMOGpm03lG94krdzHMrUr5ySNwRYK7zDUGtYkkPb3VpHAxkEgQYAXBBLhcIlEOoQS+pHgIBEAIIgYtGHaMGxEPIwYfRouzFCFHMgM+TbIKHYSw7oRBy2kbHlhHIl9FGyOiEaTgkINLwHEynWioIx2FEQCeJho3AGeEZUCqPfmKECN9HAENYlBINVcFbUwZQbjX658UcBlqHgA4TwtLDN0HAmQANoYCAYwlbAIxkWMdA7oGCBgrwcCiBP3XMy4sYoIADs=";
                  tipologiaTreno = "S7";
                }
                

              } 
              else if(treno.numeroTreno.toString().startsWith('33')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/7/7e/Simbolo_Treno_Regionale_Veloce.svg";
                tipologiaTreno = "Regionale Veloce";
              }
              else {
                // Altrimenti sono treni regionali
                tipologiaTreno = "Regionale";
              }
            }

            return (
              <tr key={index} className="border-b">
                <td className="py-4 px-5">
                  <img src={treno.iconaTreno} alt="Compagnia ferroviaria" className="w-12 h-auto object-contain" />
                </td>
                <td className="py-4 px-5">{treno.numeroTreno}</td>
                <td className="py-4 px-5">
                  {treno.tipologiaTrenoIcona ? (
                    <img src={treno.tipologiaTrenoIcona} alt="Tipologia Treno" className="w-8 h-auto object-contain" />
                  ) : (
                    <span>{tipologiaTreno}</span>
                  )}
                </td>
                <td className="py-4 px-5">{treno.destinazione}</td>
                <td className="py-4 px-5">
                  {new Date(treno.orarioPartenza).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
                </td>
                <td className="py-4 px-5">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      treno.ritardo > 0 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                    }`}
                  >
                    {treno.ritardo > 0 ? `+${treno.ritardo} min` : '0 min'}
                  </span>
                </td>
                <td className="py-4 px-5 text-sm">
                  {treno.statoTreno ? treno.statoTreno : 'N/D'}
                </td>
                <td className="py-4 px-5">{treno.binarioProgrammato || 'N/D'}</td>
                <td className="py-4 px-5">{treno.binarioReale || 'N/D'}</td>
                <td className="py-4 px-5">{treno.categoria}</td>
                <td className="py-4 px-5">{tipologiaTreno}</td>
                <td className="py-4 px-5">{treno.binarioEffettivoPartenzaDescrizione || 'N/D'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableTreni;
