import Parse from 'parse';

export const handleVideoSubmit = async (e, description, video, setMessage, setShowAlert, onVideoAdded) => {
    e.preventDefault();

    // Caricare il video su Parse
    const parseFile = new Parse.File(video.name, video);

    try {
        await parseFile.save();

        // Recupera l'URL originale del file caricato
        const originalUrl = parseFile.url();

        // Usa CORS Anywhere come proxy per aggirare il problema CORS
        // Assicurati di aver attivato l'accesso temporaneo se necessario tramite https://cors-anywhere.herokuapp.com/corsdemo
        const corsAnywhereUrl = 'https://cors-anywhere.herokuapp.com/';
        const videoUrlThroughProxy = `${corsAnywhereUrl}${originalUrl}`;

        // Salvare i dati del video nel database Parse
        const VideoData = Parse.Object.extend('VideoData');
        const videoData = new VideoData();

        videoData.set('description', description);
        videoData.set('videoUrl', videoUrlThroughProxy);

        await videoData.save();
        console.log('Video data saved successfully');

        // Mostrare messaggio di successo
        setMessage('Il video è stato caricato con successo');
        setShowAlert(true);

        // Notificare il componente padre che un nuovo video è stato aggiunto
        if (typeof onVideoAdded === 'function') {
            onVideoAdded();
        }
    } catch (error) {
        console.error('Error while saving video data: ', error);
        // Mostrare messaggio di errore
        setMessage('Errore nel caricamento del video');
        setShowAlert(true);
    }
};

