import React, { useState } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import stations from '../../assets/olther/stations.json';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { FaSearch } from 'react-icons/fa';
import 'leaflet/dist/leaflet.css';
import '../../assets/css/dettaglistazione.css';

// Import necessario per correggere le icone dei marker
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const regionMapping = {
  1: 'Lombardia',
  2: 'Liguria',
  3: 'Piemonte',
  4: "Valle d'Aosta",
  5: 'Lazio',
  6: 'Umbria',
  7: 'Molise',
  8: 'Emilia Romagna',
  10: 'Friuli Venezia Giulia',
  11: 'Marche',
  12: 'Veneto',
  13: 'Toscana',
  14: 'Sicilia',
  15: 'Basilicata',
  16: 'Puglia',
  17: 'Calabria',
  18: 'Campania',
  19: 'Abruzzo',
  20: 'Sardegna',
  22: 'Trentino Alto Adige',
};

const DettagliStazione = () => {
  const [selectedStation, setSelectedStation] = useState(null);
  const [dettagliStazione, setDettagliStazione] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const filteredStations = (inputVal) => {
    return stations
      .filter((station) =>
        station.long_name.toLowerCase().includes(inputVal.toLowerCase())
      )
      .slice(0, 20);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: filteredStations(inputValue),
    inputValue,
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue || '');
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectedStation(selectedItem);
      } else {
        setSelectedStation(null);
      }
    },
    itemToString: (item) => (item ? item.long_name : ''),
  });

  const fetchDettagliStazione = async () => {
    if (selectedStation) {
      const { code } = selectedStation;
      const selectedStationObj = stations.find((st) => st.code === code);
      if (selectedStationObj) {
        const { region } = selectedStationObj;
        try {
          setIsLoading(true);
          const response = await axios.get(
            `https://trenitaliapijs-production.up.railway.app/station-details/${code}/${region}`
          );
          const data = response.data;
          setDettagliStazione(data);
        } catch (error) {
          console.error('Errore nel recupero dei dettagli della stazione:', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <div className="detailsstation-container">
      <div className="detailsstation-input-container">
        <input
          {...getInputProps({
            placeholder: 'Seleziona una stazione...',
            className: 'detailsstation-select-input',
          })}
        />
        <ul {...getMenuProps()} className="detailsstation-menu">
          {isOpen &&
            filteredStations(inputValue).map((item, index) => (
              <li
                key={index}
                {...getItemProps({ item, index })}
                className={`detailsstation-menu-item ${
                  highlightedIndex === index
                    ? 'detailsstation-highlighted-item'
                    : ''
                }`}
              >
                {item.long_name}
              </li>
            ))}
        </ul>
      </div>

      {selectedStation && (
        <button
          onClick={fetchDettagliStazione}
          className="detailsstation-button"
          disabled={isLoading}
        >
          <FaSearch className="detailsstation-icon" />
          {isLoading ? 'Caricamento...' : 'Invia'}
        </button>
      )}

      {dettagliStazione && (
        <div className="detailsstation-details">
          <h3>{dettagliStazione.localita.nomeLungo}</h3>
          <p>
            <strong>Codice Stazione:</strong> {dettagliStazione.codiceStazione}
          </p>
          <p>
            <strong>Regione:</strong> {regionMapping[dettagliStazione.codReg]}
          </p>
          <p>
            <strong>Latitudine:</strong> {dettagliStazione.lat}
          </p>
          <p>
            <strong>Longitudine:</strong> {dettagliStazione.lon}
          </p>
          <p>
            <strong>Città:</strong> {dettagliStazione.nomeCitta}
          </p>
          <div className="detailsstation-map-container">
            <MapContainer
              center={[dettagliStazione.lat, dettagliStazione.lon]}
              zoom={13}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[dettagliStazione.lat, dettagliStazione.lon]}
              />
            </MapContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default DettagliStazione;
