import React from 'react';
import autostima from '../assets/img/autostima.jpg';
import autostima2 from '../assets/img/autostima2.jpg';
import autostima3 from '../assets/img/autostima3.png';
import '../assets/css/autostima.css'; // Import the CSS file we'll create
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';

function Autostima() {
  return (
    <div>
      <Navbar />
      <div className="autostima-container">
      <h1 className="autostima-title" style={{ fontFamily: 'Arial Black', fontSize: '60px' }}>NGLPop</h1>
      <div className="div-centrale">
        <p className="autostima-date">9 Luglio 2024 • 22:00 Psicologia</p>
        <div className="autostima-content">
          <h1 className="autostima-heading" style={{ fontFamily: 'Arial Black', fontSize: '36px' }}>L'AUTOSTIMA</h1>
          <h2>Che cos’è l’autostima?</h2>
          <p><strong>Avere alta autostima significa:</strong></p>
          <ul>
            <li>Riuscire ad ammettere quali sono i nostri <strong>difetti</strong> senza però oscurare i <strong>pregi</strong>.</li>
            <li>Essere in grado di <strong>apprezzarsi</strong>, paragonando i nostri risultati al posto di paragonarli a quelli degli altri.</li>
            <li>Accettare il nostro <strong>fallimento</strong> nel raggiungere un obbiettivo piuttosto che un traguardo, senza mai pensare di essere un “<strong>fallito</strong>”.</li>
          </ul>
          <p>L'<strong>autostima</strong> è come se fosse una valutazione finale di ciò che siamo noi.</p>
          <div className="autostima-image">
            <img src={autostima} alt="Foto sull'autostima" />
            <p>Foto sull'autostima</p>
          </div>
          <h2>Che cos’è la mancanza di autostima?</h2>
          <p>La <strong>mancanza di autostima</strong> è un fenomeno che nasce da una complessità di fattori collegati...</p>
          <div className="autostima-image">
            <img src={autostima2} alt="Mancanza di autostima" />
          </div>
          <h2>L'impatto dell'era digitale sull'autostima</h2>
          <p>L’impatto dell’<strong>ambiente digitale</strong> non è da trascurare...</p>
          <div className="autostima-image">
            <img src={autostima3} alt="Impatto digitale" />
          </div>
        </div>
      </div>
      <h3 className="autostima-fonti">Fonti: Nglcila.com</h3>
      </div>
      <Footer />
    </div>
  );
}

export default Autostima;
