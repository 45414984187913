import React from 'react';
import { Link } from 'react-router-dom';

const Pagina404 = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-pink-200 via-purple-200 to-indigo-200 p-4">
      <div className="text-center">
        <img
          src="https://nglcila.com/static/media/ngllogo.50a3cc7be696931d6594.jpg"
          alt="Logo NGL Cila"
          className="w-40 h-40 mx-auto mb-8 animate-pulse"
        />
        <h1 className="text-6xl font-bold text-gray-800">
          Oops! Pagina non trovata
        </h1>
        <p className="mt-4 text-2xl text-gray-700">
          Sembra che questa pagina sia sfuggita tra i gossip...
        </p>
        <p className="mt-2 text-gray-600">
          Ma non preoccuparti, abbiamo tante notizie interessanti per te!
        </p>
        <div className="mt-6 flex flex-col sm:flex-row sm:justify-center gap-4">
          <Link to="/news">
            <button className="px-8 py-3 bg-pink-600 text-white rounded-lg shadow hover:bg-pink-700 transition duration-300">
              Scopri gli ultimi gossip
            </button>
          </Link>
          <Link to="/home">
            <button className="px-8 py-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-300">
              Torna alla Home
            </button>
          </Link>
        </div>
      </div>
      
    </div>
  );
};

export default Pagina404;

