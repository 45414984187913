import React from "react";
import {
  FaBicycle,
  FaMapMarkedAlt,
  FaCogs,
  FaSign,
  FaLightbulb,
  FaCompass,
} from "react-icons/fa";
import ciclovieLogo from "../../assets/img/ciclovieCila.svg";

// Sezione Hero con immagine di sfondo, overlay e titolo
const HeroSection = () => (
  <section
    id="hero"
    className="relative h-screen bg-cover bg-center"
    style={{
      backgroundImage:
        "url('https://parsefiles.back4app.com/h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA/0e2d6ff368cf0492a4000c3c599e4845_IMG_2680.jpeg')",
    }}
  >
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
      <div className="flex items-center space-x-4">
        <FaBicycle className="text-6xl text-white" />
        <h1 className="text-5xl md:text-7xl font-extrabold text-white">
          Ciclovie di Cila
        </h1>
      </div>
      <p className="mt-4 text-xl md:text-2xl text-white max-w-3xl">
        Scopri un progetto innovativo e sostenibile che unisce percorsi sterrati e
        asfaltati in una rete ciclabile di oltre 100 km.
      </p>
      <a
        href="#overview"
        className="mt-8 px-6 py-3 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition duration-300 shadow-lg"
      >
        Scopri il Progetto
      </a>
    </div>
  </section>
);

// Sezione Panoramica con due colonne: testo descrittivo e immagine del logo
const OverviewSection = () => (
  <section id="overview" className="py-16 bg-gradient-to-r from-indigo-600 to-purple-600">
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <h2 className="text-3xl font-bold mb-4 text-white">La Visione del Progetto</h2>
          <p className="text-lg text-white mb-4">
            "Ciclovie di Cila" nasce per trasformare la mobilità e valorizzare il territorio attraverso il ciclismo. L'idea è integrare percorsi esistenti – sia sterrati che asfaltati – in una rete continua, sicura e accessibile.
          </p>
          <p className="text-lg text-white">
            La nostra visione collega Cilavegna, Novara, Vigevano, Garlasco e, in prospettiva, Pavia, offrendo un’esperienza unica sia a chi si sposta per necessità che agli appassionati di avventure su due ruote.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <img
            src={ciclovieLogo}
            alt="Ciclovie di Cila"
            className="rounded-lg shadow-2xl w-3/4"
          />
        </div>
      </div>
    </div>
  </section>
);

// Sezione di descrizione dettagliata del progetto
const DetailedProjectSection = () => (
  <section id="detailed-project" className="py-16 bg-white">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Dettaglio del Progetto
      </h2>
      <div className="space-y-6">
        <p className="text-lg text-gray-700">
          Il progetto “Ciclovie di Cila” è stato concepito per valorizzare il territorio in modo sostenibile. L’obiettivo principale è unificare i frammentati percorsi ciclabili esistenti e trasformarli in un network integrato di oltre 100 km.
        </p>
        <p className="text-lg text-gray-700">
          Attraverso questo percorso, le aree centrali e periferiche di Cilavegna saranno collegate alle città di Novara, Vigevano e Garlasco. È prevista, inoltre, una futura espansione verso Pavia, che renderà la rete un modello di mobilità regionale.
        </p>
        <p className="text-lg text-gray-700">
          La modernizzazione delle infrastrutture comprende un aggiornamento completo della segnaletica, l’implementazione di sistemi di numerazione unificati e l’introduzione di tecnologie digitali per la manutenzione e il monitoraggio in tempo reale dello stato dei percorsi.
        </p>
        <p className="text-lg text-gray-700">
          Coordinato dal Gruppo NGL Cila, il progetto non solo migliorerà la fruibilità delle strade, ma favorirà anche lo sviluppo del turismo ciclistico e l’economia locale, dando nuova linfa vita alle comunità coinvolte.
        </p>
      </div>
    </div>
  </section>
);

// Componente "FeatureCard" per evidenziare singoli obiettivi nella sezione Obiettivi
const FeatureCard = ({ icon: Icon, title, children }) => (
  <div className="bg-white rounded-lg shadow-2xl p-6 transition transform hover:scale-105 duration-300">
    <div className="flex items-center mb-4">
      <Icon className="text-4xl text-pink-500 mr-4" />
      <h3 className="text-2xl font-semibold">{title}</h3>
    </div>
    <p className="text-gray-700 text-lg">{children}</p>
  </div>
);

// Sezione Obiettivi e Gestione del progetto
const ObjectivesSection = () => (
  <section id="objectives" className="py-16 bg-gray-50">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">Obiettivi e Gestione</h2>
      <div className="grid md:grid-cols-3 gap-8">
        <FeatureCard icon={FaMapMarkedAlt} title="Interconnessione Territoriale">
          Collegare i centri urbani e le aree rurali, creando un percorso integrato di oltre 100 km.
        </FeatureCard>
        <FeatureCard icon={FaCogs} title="Modernizzazione Stradale">
          Aggiornare e uniformare la segnaletica e le infrastrutture per garantire sicurezza e fruibilità.
        </FeatureCard>
        <FeatureCard icon={FaSign} title="Gestione e Monitoraggio">
          Affidare la regolamentazione, la numerazione e il controllo dei percorsi al Gruppo NGL Cila.
        </FeatureCard>
      </div>
    </div>
  </section>
);

// Sezione Impatto e Benefici del progetto
const ImpactSection = () => (
  <section id="impact" className="py-16 bg-gradient-to-r from-purple-500 to-indigo-600">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12 text-white">Impatto e Benefici</h2>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 mb-8 md:mb-0">
          
        </div>
        <div className="md:w-1/2 md:pl-12">
          <p className="text-lg text-white mb-4">
            Il progetto riduce l’inquinamento e il traffico, incentivando l’uso delle biciclette, anche elettriche, e promuovendo stili di vita più sani.
          </p>
          <p className="text-lg text-white">
            Favorendo il turismo ciclistico e l’economia locale, "Ciclovie di Cila" migliora la qualità della vita e rafforza la coesione sociale nelle comunità interessate.
          </p>
        </div>
      </div>
    </div>
  </section>
);

// Sezione Prospettive Future con focus sulle innovazioni e collaborazioni
const FuturePerspectivesSection = () => (
  <section id="future" className="py-16 bg-gradient-to-r from-teal-500 to-blue-500">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-8 text-white">
        Prospettive Future
      </h2>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-8">
          <p className="text-lg text-white mb-4">
            Guardando avanti, il progetto si espanderà integrando tecnologie smart e soluzioni IoT per un monitoraggio continuo. Questo permetterà di raccogliere dati in tempo reale per migliorare la sicurezza e la manutenzione della rete ciclabile.
          </p>
          <p className="text-lg text-white">
            Inoltre, saranno promosse collaborazioni con enti locali e istituzioni internazionali, rendendo "Ciclovie di Cila" un modello di riferimento per la mobilità sostenibile.
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center">
          <FaCompass className="text-8xl text-white" />
        </div>
      </div>
    </div>
  </section>
);

// Sezione Call-to-Action per invitare l’utenza a partecipare all’iniziativa
const CallToActionSection = () => (
  <section id="cta" className="py-16 bg-pink-500">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-3xl font-bold text-white mb-4">
        Unisciti alla Rivoluzione Sostenibile
      </h2>
      <p className="text-lg text-white mb-8">
        La trasformazione del territorio è nelle tue mani. Diventa parte attiva di questo progetto pionieristico e contribuisci a creare un futuro più verde e sicuro per tutti.
      </p>
      <a
        href="#contact"
        className="px-8 py-4 bg-white text-pink-500 font-semibold rounded-full hover:bg-gray-100 transition duration-300 shadow-lg"
      >
        Prendi Parte
      </a>
    </div>
  </section>
);

// Componente principale che unisce tutte le sezioni
const CiclovieProject = () => {
  return (
    <div className="overflow-hidden">
      <HeroSection />
      <OverviewSection />
      <DetailedProjectSection />
      <ObjectivesSection />
      <ImpactSection />
      <FuturePerspectivesSection />
      <CallToActionSection />
    </div>
  );
};

export default CiclovieProject;
