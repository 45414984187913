import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CiclovieProject from "../components/layout/CiclovieLayout";

function NuovoProgettoCiclovie() {
    return <div>
        <Navbar />
        <CiclovieProject />
        <Footer />
    </div>;
}

export default NuovoProgettoCiclovie;