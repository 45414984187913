// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home'; // Importa il componente Home
import './index.css'; // Assicurati di importare il file CSS con il font
import News from './pages/News';
import Parse from 'parse';
import Galleria from './pages/Galleria';
import Video from './pages/Video';
import Altro from './pages/Altro';
import OrarioLineeAutoguidovie from './pages/OrarioLineeAutoguidovie';

import ScopriIlTuoTreno from './pages/ScopriilTuoTreno';
import AcquistoMagliette from './pages/AcquistoMagliette';

import Shop from './pages/Shop';
import DownloadApp from './pages/DownloadApp';
import Eventi from './pages/Eventi';
import Policy from './pages/PolicyPage';
import LoginAdmin from './pages/LoginAdmin';
import DashboardAdmin from './pages/AdminDashboardPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AccountDashboard from './pages/AccountDashboard';
import NGLPop from './pages/NGLPop';
import ClassificaBellezzaPage from './pages/ClassificaBellezzaPage';
import Autostima from './pages/AutostimaPage';
import CasaBianca from './pages/CasabiancaPage';
import Cannabis from './pages/CannabisPage';
import Creazionevideogiochi from './pages/CreazioneVideogiochiPage';
import Triangolobermuda from './pages/TriangoloBermudaPage';
import NotFound from './pages/NotFound';
import QuizNGL from './pages/QuizNGL';
import PaymentSucceful from './pages/PaymentSucceful';
import ChiSiamoPage from './pages/ChiSiamoPage';
import NuovoProgettoCiclovie from './pages/NuovoProgettoCiclovie';



const PARSE_APPLICATION_ID = 'h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA';
const PARSE_JAVASCRIPT_KEY = 'Ts74nlTiApt0TLezItum716SuEnWEIsujWFpjsct';
const PARSE_HOST_URL = 'https://parseapi.back4app.com';

Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

Parse.LiveQuery.on('open', () => {
  console.log('LiveQuery connection opened');
});

Parse.LiveQuery.on('close', () => {
  console.log('LiveQuery connection closed');
});

Parse.LiveQuery.on('error', (error) => {
  console.error('LiveQuery error:', error);
});

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/news" element={<News />} />
        <Route path="/archiviofoto" element={<Galleria />} />
        <Route path="/video" element={<Video />} />
        <Route path="/altro" element={<Altro />} />
        <Route path="/orarioautoguidovie" element={<OrarioLineeAutoguidovie />} />
        <Route path="/scopriltuotreno" element={<ScopriIlTuoTreno />} />
        <Route path="/acquistomagliette" element={<AcquistoMagliette />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/downloadapp" element={<DownloadApp />} />
        <Route path="/eventi" element={<Eventi />} />
        <Route path="/gdpr" element={<Policy />} />
        <Route path="/login-admin" element={<LoginAdmin />} />
        <Route path="/dashboard-admin" element={<DashboardAdmin/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/register" element={<RegisterPage/>} />
        <Route path="/ngl-pop" element={<NGLPop/>} />
        <Route path="/account-dashboard" element={<AccountDashboard/>} />
        <Route path="/classifica-bellezza" element={<ClassificaBellezzaPage/>} />
        <Route path="/cos-e-l-autostima" element={<Autostima/>} />
        <Route path="/com-e-fatta-la-casa-bianca" element={<CasaBianca/>} />
        <Route path="/gli-effetti-della-cannabis" element={<Cannabis/>} />
        <Route path="/come-vengono-creati-i-videogiochi" element={<Creazionevideogiochi/>} />
        <Route path="/triangolo-delle-bermuda" element={<Triangolobermuda/>} />
        <Route path="/minigioco" element={<QuizNGL/>} />
        <Route path="/pagamento-effettuato" element={<PaymentSucceful/>} />
        <Route path="/chisiamo" element={<ChiSiamoPage/>} />
        <Route path="/nuovo-progetto-ciclovie" element={<NuovoProgettoCiclovie/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;




