// AccountDashboardLayout.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import {
  FaUser,
  FaBoxOpen,
  FaShoppingCart,
  FaServicestack,
  FaMedal,
  FaEllipsisH,
} from 'react-icons/fa';
import '../../assets/css/account_dashboard.css';

const AccountDashboardLayout = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const db = getDatabase();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userRef = ref(db, `users/${user.uid}`);
        get(userRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              setUsername(snapshot.val().username);
            }
          })
          .catch((error) => {
            console.error('Errore durante il recupero del username: ', error);
          });
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);

  const sections = [
    { name: 'Account', icon: <FaUser size={30} />, link: '/account' },
    { name: 'Ordini', icon: <FaBoxOpen size={30} />, link: '/orders' },
    { name: 'Vendite', icon: <FaShoppingCart size={30} />, link: '/sales' },
    { name: 'Servizi Abilitati', icon: <FaServicestack size={30} />, link: '/services' },
    { name: 'Punteggi', icon: <FaMedal size={30} />, link: '/scores' },
    { name: 'Altro', icon: <FaEllipsisH size={30} />, link: '/other' },
  ];

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Errore durante il logout: ', error);
      });
  };

  return (
    <div className="account_dsh_container">
      <h1 className="account_dsh_title">Benvenuto {username}</h1>
      <div className="account_dsh_menu">
        {sections.map((section) => (
          <div key={section.name} className="account_dsh_menu_item">
            <div className="account_dsh_icon">{section.icon}</div>
            <h2 className="account_dsh_section_name">{section.name}</h2>
            <a href={section.link} className="account_dsh_link">
              Vai alla sezione
            </a>
          </div>
        ))}
      </div>
      <button onClick={handleLogout} className="account_dsh_logout_button">
        Esci dall'Account
      </button>
    </div>
  );
};

export default AccountDashboardLayout;


