import React from 'react';
import sfondo from '../assets/img/sfondo.png';
import '@google/model-viewer';
import koldy from '../assets/olther/koldy.glb';
import '../assets/css/creazionevideogiochi.css'; // Importa il file CSS
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';

function Creazionevideogiochi() {
    return (
        <div className="creazionevideogiochi-background" style={{ backgroundImage: `url(${sfondo})` }}>
            <Navbar />
            <h1 className="creazionevideogiochi-title">NGLPop</h1>
            <div className="creazionevideogiochi-div-centrale">
                <p className="creazionevideogiochi-date">23 Agosto 2024 * 13:27 Videogiochi e Tecnologia</p>
                <div className="creazionevideogiochi-container">
                    <h1 className="creazionevideogiochi-article-title">Videogiochi: Come vengono creati?</h1>
                    <p className="creazionevideogiochi-article-text">
                        I <strong>videogiochi</strong> sono ormai il <strong>passatempo preferito</strong> di tutti noi ragazzi, dai bambini agli adolescenti, e persino alcuni adulti. Passiamo da <strong>titoli competitivi di fama mondiale</strong> come <strong>Fortnite</strong>, <strong>League of Legends</strong>, e <strong>GTA</strong>, a <strong>Minecraft</strong>, che è molto più di un semplice videogioco grazie alle infinite possibilità che offre. E non dimentichiamo i <strong>titoli mobile</strong> come <strong>Brawl Stars</strong>. Ma vi siete mai chiesti come vengono creati?
                    </p>
                    <div className="creazionevideogiochi-row">
                        <div className="creazionevideogiochi-col">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Rocket_League_logo.svg" alt="Rocket League Logo" className="creazionevideogiochi-img-fluid creazionevideogiochi-rounded" />
                            <p>Logo di Rocket League, un noto videogioco di calcio tra auto da corsa. Fonte: Wikipedia Immages</p>
                        </div>
                    </div>
                    <h1 className="creazionevideogiochi-article-title">Come si creano</h1>
                    <p className="creazionevideogiochi-article-text">
                        Alla base di ogni videogioco, prima della loro produzione, vi vanno le <strong>idee</strong>. Ebbene sì, tutti i videogiochi moderni sono sempre partiti da <strong>idee</strong>, originali o copie, ma che unendosi formano il <strong>prototipo ideale</strong> di un videogioco. Può esserci stata anche una <strong>progettazione su carta e penna</strong> prima di ogni creazione per valutare le <strong>meccaniche</strong> e lo <strong>scopo</strong> del videogioco. Le idee devono anche corrispondere al <strong>tema</strong> del videogioco, poiché idee fuori tema rendono il videogioco senza senso.
                    </p>
                    <h2 className="creazionevideogiochi-subtitle">La realizzazione del videogioco.</h2>
                    <h3 className="creazionevideogiochi-subtitle">Parte Grafica</h3>
                    <p className="creazionevideogiochi-article-text">
                        Dopo aver scelto il tema, ideato i personaggi, le meccaniche e l'ambiente di gioco, si può partire con la realizzazione vera e propria. Il team di sviluppo qui si divide nelle loro "specializzazioni". I <strong>concept artist</strong> creano i <strong>modelli 3D</strong> e le <strong>texture</strong>, usando software come <strong>Blender</strong>, <strong>3ds Max</strong> e <strong>AutoCAD</strong> per i modelli 3D, mentre utilizzano principalmente <strong>Photoshop</strong> o programmi simili per le texture.
                    </p>
                    <div className="creazionevideogiochi-row">
                        <div className="creazionevideogiochi-col">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg" alt="Photoshop Logo" className="creazionevideogiochi-img-fluid creazionevideogiochi-rounded" />
                            <p>Logo di Photoshop (Spoiler, lo usiamo anche noi nel team di NGL). Fonte: Wikipedia Immages</p>
                            <model-viewer
                                src={koldy}
                                alt="A 3D model"
                                auto-rotate
                                camera-controls
                                ar
                                className="creazionevideogiochi-model-viewer"
                            ></model-viewer>
                            <p>Esempio di modello 3D con texture, che si può pure ruotare!</p>
                        </div>
                    </div>
                    <h2 className="creazionevideogiochi-subtitle">Programmazione</h2>
                    <p className="creazionevideogiochi-article-text">
                        Ora, dopo aver creato le componenti grafiche di un videogioco, bisogna passare al <strong>nucleo del videogioco</strong>, ovvero il <strong>motore grafico</strong>. Mediante ciò, gli sviluppatori delle case videoludiche possono integrare i vari componenti grafici e, tramite la programmazione, personalizzarli (non graficamente ma a livello di <strong>meccaniche</strong>), programmando così anche le <strong>interfacce grafiche (GUI)</strong>.
                    </p>
                    <div className="creazionevideogiochi-row">
                        <div className="creazionevideogiochi-col">
                            <img src="https://ue-cdn.artstation.com/imgproxy/FRRZ3IOerOGxth0w3eQG4wsA_YP11qRv4Q_bCJba9A0/filename:1_wctyxpis.jpg/resizing_type:fit/width:1280/height:720/aHR0cHM6Ly9kMWl2N2RiNDR5aGd4bi5jbG91ZGZyb250Lm5ldC92aWRlby90aHVtYm5haWxzLzk4YTNjY2VjLTNmMzItNDVkMS1iMjY1LTA5NzQzZTc1OTM4OC8xX3djdHl4cGlzLmpwZw" alt="Unreal Engine Screenshot" className="creazionevideogiochi-img-fluid creazionevideogiochi-rounded" />
                            <p>Schermata di Unreal Engine. Fonte: Epic Games</p>
                        </div>
                    </div>
                    <h2 className="creazionevideogiochi-subtitle">Distribuzione</h2>
                    <p className="creazionevideogiochi-article-text">
                        Dopo aver <strong>programmato il gioco</strong>, testato varie volte e corretto gli eventuali bug, è ora di <strong>distribuirlo</strong> a tutti. In questo momento, il codice dei videogiochi viene <strong>compilato in linguaggio macchina</strong> (ovvero in una lingua comprensibile da tutti i dispositivi), in base alla piattaforma di destinazione.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Creazionevideogiochi;