import React from "react";

const ChiSiamoLayout = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Chi Siamo
        </h1>

        <div className="bg-white shadow-lg rounded-lg p-8">
          {/* Sezione "Cos'è NGLCila?" */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-teal-600 mb-4">
              Cos'è NGLCila?
            </h2>
            <p className="text-gray-700 leading-relaxed">
              NGLCila è un progetto di giornalismo e gossip dedicato ai ragazzi di
              Cilavegna e dintorni. La nostra missione è fornire notizie fresche,
              divertenti e coinvolgenti che riguardano la comunità locale. Che si
              tratti di eventi, storie di persone, o semplici curiosità, NGLCila è
              qui per tenervi informati e intrattenuti.
            </p>
          </section>

          {/* Sezione "La Nostra Storia" */}
          <section>
            <h2 className="text-2xl font-semibold text-teal-600 mb-4">
              La Nostra Storia
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Tutto è cominciato il 12 gennaio 2023, quando abbiamo lanciato il nostro
              account Instagram per rispondere agli NGL Anonimi. Da quel momento in
              poi, la community è cresciuta esponenzialmente e abbiamo deciso di
              trasformare questa passione in attività concrete, ampliando il progetto
              e portando il giornalismo e il gossip locale a un nuovo livello.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChiSiamoLayout;
