import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import Pagina404 from "../components/layout/NotFoundLayout";

function NotFound() {
    return (
        <div>
            <Navbar />
            <Pagina404 />
            <Footer />
        </div>
    );
}

export default NotFound;