import { useState } from "react";
import { useNavigate } from "react-router-dom";  // Non serve useLocation in questo caso
import { auth, provider, db } from "./firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { ref, get, set, child } from "firebase/database";

const useLoginLogic = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Legge il valore "redirectFrom" dal sessionStorage, se presente
  const redirectFrom = sessionStorage.getItem("redirectFrom") || "";
  console.log("useLoginLogic -> redirectFrom letto dal sessionStorage:", redirectFrom);

  // Funzione per reindirizzare l'utente dopo il login
  const redirectUserAfterLogin = () => {
    if (redirectFrom !== "") {
      console.log("useLoginLogic -> Redirecting to:", redirectFrom);
      sessionStorage.removeItem("redirectFrom");
      navigate(redirectFrom);
    } else {
      console.log("useLoginLogic -> Nessun redirectFrom trovato. Redirecting to: /home");
      navigate("/home");
    }
  };

  const handleGoogleSignIn = async () => {
    console.log("useLoginLogic -> Avvio Google Sign In");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("useLoginLogic -> Google Sign In riuscito, user:", user);
      redirectUserAfterLogin();
      return user;
    } catch (err) {
      console.error("useLoginLogic -> Errore durante Google Sign In:", err);
      setError(err.message);
      return null;
    }
  };

  const handleEmailPasswordSignIn = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    console.log("useLoginLogic -> Tentativo di sign in con email:", email);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("useLoginLogic -> Email/Password Sign In riuscito");
      redirectUserAfterLogin();
    } catch (err) {
      console.error("useLoginLogic -> Errore durante Email/Password Sign In:", err);
      if (err.code === "auth/invalid-credential") {
        setError("Password non corretta");
      } else {
        setError(err.message);
      }
    }
  };

  const checkIfUsernameExists = async (uid) => {
    try {
      const dbRef = ref(db);
      const snapshot = await get(child(dbRef, `users/${uid}/username`));
      const exists = snapshot.exists();
      console.log("useLoginLogic -> checkIfUsernameExists per uid:", uid, " =>", exists);
      return exists;
    } catch (err) {
      console.error("useLoginLogic -> Errore durante la verifica dell'username:", err);
      return false;
    }
  };

  const saveUserData = async (user, username) => {
    try {
      const userRef = ref(db, `users/${user.uid}`);
      const ip = await fetchUserIP();
      const userAgent = navigator.userAgent;
      const userData = {
        email: user.email,
        uid: user.uid,
        username: username,
        registrationDate: new Date().toISOString(),
        ip: ip || "IP non disponibile",
        userAgent: userAgent,
      };
      console.log("useLoginLogic -> Salvataggio dei dati utente:", userData);
      await set(userRef, userData);
    } catch (err) {
      console.error("useLoginLogic -> Errore durante il salvataggio dei dati:", err);
    }
  };

  // Funzione per ottenere l'IP dell'utente
  const fetchUserIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      console.log("useLoginLogic -> IP ottenuto:", data.ip);
      return data.ip;
    } catch (err) {
      console.error("useLoginLogic -> Errore nel recuperare l'IP:", err);
      return null;
    }
  };

  return {
    handleGoogleSignIn,
    handleEmailPasswordSignIn,
    checkIfUsernameExists,
    saveUserData,
    error,
  };
};

export default useLoginLogic;














