import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import ChiSiamoLayout from "../components/layout/ChiSiamoLayout";

function ChiSiamoPage() {
    return <div>
        <Navbar />
        <ChiSiamoLayout />
        <Footer />
    </div>;
}

export default ChiSiamoPage;