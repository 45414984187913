import React from 'react';
import { FaAndroid, FaApple, FaGooglePlay } from 'react-icons/fa';

const DownloadPage = () => {
  return (
    <div className="flex flex-col items-center text-center p-4">
      <div className="my-8">
        <h1 className="text-3xl font-bold font-inter">Link per i vari download</h1>
        <h2 className="text-xl my-4 font-inter">
          Scarica la nostra Progressive Web App per un'esperienza veloce e intuitiva. Segui le istruzioni nel link per configurare la PWA sul tuo dispositivo preferito.
        </h2>
        <div className="flex justify-center my-3">
          <a href="https://app.nglcila.com" target="_blank" rel="noopener noreferrer" className="mx-2">
            <button className="flex items-center px-4 py-2 border-2 border-blue-500 text-blue-500 font-inter rounded-lg hover:bg-blue-500 hover:text-white transition duration-300">
              <FaAndroid size={30} className="mr-2" /> Android
            </button>
          </a>
          <a href="https://app.nglcila.com" target="_blank" rel="noopener noreferrer" className="mx-2">
            <button className="flex items-center px-4 py-2 border-2 border-blue-500 text-blue-500 font-inter rounded-lg hover:bg-blue-500 hover:text-white transition duration-300">
              <FaApple size={30} className="mr-2" /> iOS
            </button>
          </a>
        </div>
      </div>
      <div className="my-8">
        <h2 className="text-xl my-4 font-inter">Scarica l'app nativa (Alpha) solo android</h2>
        <p className="font-inter">
          Stiamo testando la versione alpha della nostra app nativa per Android. Clicca il pulsante qui sotto per scaricare e prova in anteprima tutte le nuove funzionalità.
        </p>
        <a href="https://expo.dev/artifacts/eas/iKqyRDuU4syZRqGMLBN5Jb.apk" target="_blank" rel="noopener noreferrer" className="mt-4">
          <button className="flex items-center px-4 py-2 bg-green-500 text-white font-inter rounded-lg hover:bg-green-700 transition duration-300">
            <FaGooglePlay size={30} className="mr-2" /> Scarica
          </button>
        </a>
      </div>
    </div>
  );
};

export default DownloadPage;
