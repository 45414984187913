import React from "react";

import image1 from '../assets/img/imagee1.png';
import image2 from '../assets/img/image2.avif';
import bermuda from '../assets/img/bermuda.jpeg';
import bermuda2 from '../assets/img/bermuda2.jpg';

import '../assets/css/triangolobermuda.css';
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";

function Triangolobermuda() {
  return (
   <div>
      <Navbar />
      <div className="effetti-background">
      <h1 className="effetti-title">NGLPop</h1>
      <div className="effetti-div-centrale">
        <p className="effetti-date">22 Settembre 2024 * 11:42 Geografia e mitologia</p>
        <div className="effetti-container">
          <h1 className="effetti-header">IL TRIANGOLO DELLE BERMUDA</h1>
          <p className="effetti-text">
            Il triangolo delle bermuda, è un luogo iconico ma alquanto spaventoso, data la percentuale alta di navi e aerei scomparsi proprio in quella zona. Ma quindi che cos’è questo triangolo delle bermude?
          </p>
          <div className="effetti-row">
            <div className="effetti-col">
              <img src={image1} alt="Image 1" className="effetti-image" />
            </div>
            <div className="effetti-col">
              <img src={image2} alt="Image 2" className="effetti-image" />
            </div>
          </div>
          <h1 className="effetti-header">DOVE SI TROVA?</h1>
          <p className="effetti-text">
            Il Triangolo delle Bermuda è una misteriosa area situata nell’Oceano Atlantico Settentrionale, famosa per la sua forma di triangolo “immaginario”. Questo triangolo è delimitato da tre punti chiave: l’arcipelago delle Bermude, Porto Rico e il punto più a sud della Florida. Questi tre punti formano i vertici del leggendario Triangolo delle Bermuda.
          </p>
          <h1 className="effetti-header">PERCHE' TRIANGOLO MALEDETTO?</h1>
          <p className="effetti-text">
            Fin dai tempi di Cristoforo Colombo, noto esploratore che ha scoperto l’America, il Triangolo delle Bermuda è stato descritto come una zona con comportamenti surreali e non consoni alla fisica, con strane luci e movimenti anomali della bussola. Questo ha gettato le basi per nuove leggende tramandate per secoli. Uno degli episodi più noti è quello del Volo 19, in cui un gruppo di bombardieri della Marina degli Stati Uniti sparì nel 1945 dopo che i piloti si disorientarono. Nonostante una ricerca estesa, né gli aerei né l’equipaggio furono mai ritrovati, alimentando ulteriormente il mistero.
          </p>
          <h1 className="effetti-header">LE TEORIE: CIVILTA' ALIENA E ATLANTIDE</h1>
          <p className="effetti-text">
            Durante il secolo scorso, molte teorie sono state proposte per spiegare la sparizione di navi e aerei, dalle più fantasiose come Atlantide e l’esistenza degli extraterrestri, a teorie più scientifiche. Tuttavia, nessuna di queste ha confermato qualcosa di concreto e affidabile. Una ipotesi scientifica più sensata potrebbe essere la formazione di uragani e tempeste che mettono in pericolo la navigazione, poiché è proprio in quell’area che si formano, ma nulla di confermato.
          </p>
          <h1 className="effetti-header">AL DI LA' DELLE LEGGENDE</h1>
          <p className="effetti-text">
            Nonostante il fascino delle leggende, le sparizioni nel Triangolo delle Bermuda non sono mai state statisticamente significative rispetto ad altre aree altrettanto trafficate dell’oceano. Questa regione, infatti, è navigata quotidianamente senza incidenti da innumerevoli imbarcazioni.
          </p>
          <h1 className="effetti-header">Bermuda: un paradiso di tranquillità e cultura vibrante</h1>
          <p className="effetti-text">
            Al di là delle leggende, le Bermuda attraggono con la loro bellezza tranquilla e una cultura vibrante. Su queste isole si trovano spiagge di sabbia rosa, acque turchesi cristalline e un’architettura colorata in toni pastello.
            Rinomata per le sue eccezionali sistemazioni di lusso, Bermuda è la scelta ideale per i viaggiatori esigenti in cerca di un rifugio idilliaco.
            Una delle caratteristiche più iconiche di Bermuda sono proprio le sue spiagge di sabbia rosa, come la Horseshoe Bay Beach, dove la morbida sabbia corallina incontra il mare azzurro, creando scenari da sogno.
          </p>
          <div className="effetti-row">
            <div className="effetti-col">
              <img src={bermuda} alt="Bermuda" className="effetti-image" />
            </div>
            <div className="effetti-col">
              <img src={bermuda2} alt="Bermuda 2" className="effetti-image" />
            </div>
          </div>
        </div>
      </div>
      <h3 className="effetti-fonti">Fonti: Wikipedia, Focus.it e astrolabioviaggi.it</h3>
      </div>
      <Footer />
    
    </div>
  );
}

export default Triangolobermuda;
