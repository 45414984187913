// MaglietteLayout.js
import React from "react";
import { FiUser , FiInstagram, FiShoppingCart, FiCreditCard } from "react-icons/fi";
import { motion } from "framer-motion";
import maglia from '../../assets/img/maglia.jpg';
import useAcquistomaglietteLogic from "../logic/acquistomagliettelogic";

const MaglietteLayout = () => {
    const { formData, handleChange, handleSubmit, colorOptions } = useAcquistomaglietteLogic();
    const sizeOptions = ['XS', 'S', 'M', 'L', 'XL'];
    const paymentMethods = [
        { value: 'online', label: 'Pagamento Online', icon: <FiCreditCard /> },
        { value: 'fisico', label: 'Pagamento alla Consegna', icon: <FiShoppingCart /> }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 py-12 px-4 sm:px-6 lg:px-8">
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="max-w-3xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden"
            >
                <div className="grid md:grid-cols-2 gap-8 p-8">
                    <div className="space-y-6">
                        <div className="aspect-w-1 aspect-h-1">
                            <img 
                                src={maglia} 
                                alt="Maglia" 
                                className="w-full h-full object-cover rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300" 
                            />
                        </div>
                        
                        <div className="bg-blue-50 p-4 rounded-lg">
                            <h3 className="text-lg font-semibold text-blue-800 mb-2">Dettagli prodotto</h3>
                            <ul className="space-y-2 text-sm text-blue-700">
                                <li>• Materiale: 100% Cotone Organico</li>
                                <li>• Manica Corta</li>
                                <li>• Stampata Digitalmente</li>
                                <li>• Spedizione in 2-3 giorni lavorativi</li>
                            </ul>
                        </div>
                    </div>

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <h1 className="text-3xl font-bold text-gray-900">Maglia NGL Cila</h1>
                        <div className="text-4xl font-bold text-blue-600">€10.99</div>

                        {/* Nome e Instagram */}
                        <div className="grid gap-4 sm:grid-cols-2">
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                                    <FiUser  className="w-5 h-5" /> Nome
                                </label>
                                <input
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="Il tuo nome"
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                                    <FiInstagram className="w-5 h-5" /> Instagram
                                </label>
                                <input
                                    name="nomeig"
                                    value={formData.nomeig}
                                    onChange={handleChange}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="@username"
                                />
                            </div>
                        </div>

                        {/* Selettore Taglia */}
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">Taglia</label>
                            <div className="grid grid-cols-3 gap-2 sm:grid-cols-5">
                                {sizeOptions.map((size) => (
                                    <button
                                        key={size}
                                        type="button"
                                        onClick={() => handleChange({ target: { name: 'taglia', value: size } })}
                                        className={`p-2 text-sm font-medium rounded-lg border ${
                                            formData.taglia === size 
                                            ? 'bg-blue-600 text-white border-blue-700' 
                                            : 'bg-white text-gray-700 border-gray-300 hover:border-blue-500'
                                        }`}
                                    >
                                        {size}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Selettore Colore */}
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">Colore</label>
                            <div className="grid grid-cols-4 gap-2">
                                {colorOptions.map((option) => (
                                    <button
                                        key={option.value}
                                        type="button"
                                        onClick={() => handleChange({ target: { name: 'colore', value: option.value } })}
                                        className={`h-12 rounded-lg border-2 ${
                                            formData.colore === option.value 
                                            ? 'border-blue-600 ring-2 ring-blue-500' 
                                            : 'border-gray-200 hover:border-blue-400'
                                        }`}
                                        style={{ backgroundColor: option.color }}
                                        title={option.label}
                                    />
                                ))}
                            </div>
                        </div>

                        {/* Quantità */}
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
                                <FiShoppingCart className="w-5 h-5" /> Quantità
                            </label>
                            <div className="flex items-center gap-2">
                                <button
                                    type="button"
                                    onClick={() => handleChange({ target: { name: 'quantita', value: Math.max(1, formData.quantita - 1) } })}
                                    className="px-3 py-1 rounded-lg bg-gray-100 hover:bg-gray-200"
                                >
                                    -
                                </button>
                                <input
                                    type="number"
                                    name="quantita"
                                    value={formData.quantita}
                                    onChange={handleChange}
                                    className="w-20 text-center px-2 py-1 border border-gray-300 rounded-lg"
                                    min="1"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleChange({ target: { name: 'quantita', value: formData.quantita + 1 } })}
                                    className="px-3 py-1 rounded-lg bg-gray-100 hover:bg-gray-200"
                                >
                                    +
                                </button>
                            </div>
                        </div>

                        {/* Metodo di Pagamento */}
                        <div className="space-y-4 pt-4">
                            <label className="text-sm font-medium text-gray-700">Metodo di Pagamento</label>
                            <div className="grid gap-2">
                                {paymentMethods.map((method) => (
                                    <label 
                                        key={method.value}
                                        className={`flex items-center p-4 rounded-lg border cursor-pointer transition-all ${
                                            formData.pagamento === method.value
                                            ? 'border-blue-500 bg-blue-50'
                                            : 'border-gray-200 hover:border-blue-300'
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="pagamento"
                                            value={method.value}
                                            checked={formData.pagamento === method.value}
                                            onChange={handleChange}
                                            className="sr-only"
                                        />
                                        <div className="flex items-center gap-3">
                                            <div className="p-2 rounded-md bg-white text-blue-600">
                                                {method.icon}
                                            </div>
                                            <span className="font-medium">{method.label}</span>
                                        </div>
                                    </label>
                                ))}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg font-medium transition-colors"
                        >
                            Conferma Ordine
                        </button>
                    </form>
                </div>
            </motion.div>
        </div>
    );
};

export default MaglietteLayout;
