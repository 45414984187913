import React from "react";

function PaymentConfirmation() {
  const handleRedirect = () => {
    window.location.href = "/home";
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center relative overflow-hidden">
      <div className="bg-gray-800 rounded-xl shadow-2xl p-10 max-w-md w-full text-center animate-fadeInUp">
        <h1 className="text-4xl font-extrabold text-transparent bg-gradient-to-r from-teal-400 to-blue-500 bg-clip-text mb-6">
          Pagamento Effettuato!
        </h1>
        <p className="text-gray-300 text-lg mb-8">
          Il pagamento è stato completato con successo. Riceverai una ricevuta via email e la tua maglietta entro 3/4 giorni lavorativi.
        </p>
        <button
          onClick={handleRedirect}
          className="bg-teal-500 hover:bg-teal-600 transition transform hover:scale-105 text-white font-bold py-3 px-8 rounded-full shadow-lg"
        >
          Torna alla Home
        </button>
      </div>

      {/* Animazione Personalizzata */}
      <style>{`
        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fadeInUp {
          animation: fadeInUp 0.8s ease-out;
        }
      `}</style>
    </div>
  );
}

export default PaymentConfirmation;


