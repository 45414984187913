// src/components/LoginPageLayout.jsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import useLoginLogic from '../logic/LoginLogicAuth';
import ngllogo from '../../assets/img/ngllogo.jpg';

Modal.setAppElement('#root'); // Imposta l'elemento principale per l'accessibilità

const LoginPageLayout = () => {
  const {
    handleGoogleSignIn,
    handleEmailPasswordSignIn,
    error,
    checkIfUsernameExists,
    saveUserData,
  } = useLoginLogic();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [instagramUsername, setInstagramUsername] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  const handleGoogleSignInAndCheck = async () => {
    const user = await handleGoogleSignIn();
    if (user) {
      setCurrentUser(user); // Salviamo l'utente attuale
      const usernameExists = await checkIfUsernameExists(user.uid);
      if (!usernameExists) {
        setIsModalOpen(true); // Mostra il popup per inserire l'username
      } else {
        // Reindirizza alla home se l'username esiste già
        window.location.href = '#';
      }
    }
  };

  const handleSaveInstagramUsername = async () => {
    if (instagramUsername.trim() !== '') {
      await saveUserData(currentUser, instagramUsername.trim());
      setIsModalOpen(false);
      window.location.href = '#'; // Reindirizza alla home dopo aver salvato i dati
    } else {
      alert('Per favore, inserisci un username valido.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 animate-fade-in">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg">
        <div className="flex justify-center">
          <img src={ngllogo} alt="Logo" className="w-16 h-16 rounded-full shadow-lg animate-fade-in" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 animate-slide-down">Benvenuto!</h2>
        <p className="mt-2 text-center text-sm text-gray-600 animate-fade-in">Accedi al tuo account</p>
        <form className="mt-8 space-y-6" onSubmit={handleEmailPasswordSignIn}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email</label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a href="/reset-password" className="font-medium text-blue-600 hover:text-blue-500 animate-fade-in">
                Password dimenticata?
              </a>
            </div>
            {error && (
              <div className="text-sm text-red-600 mt-2">
                {error}
              </div>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Accedi
            </button>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={handleGoogleSignInAndCheck}
              className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Accedi con Google
            </button>
          </div>
          <div className="text-center mt-6">
            <a href="/register" className="font-medium text-blue-600 hover:text-blue-500 animate-fade-in">
              Non hai un account? Registrati
            </a>
          </div>
        </form>

        {/* Modal per inserire l'username Instagram */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Inserisci Username"
          className="bg-white rounded-lg max-w-md mx-auto p-6 shadow-lg"
          overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center"
        >
          <h2 className="text-xl font-semibold mb-4">Inserisci il tuo username</h2>
          <input
            id="instagram-username"
            name="instagram-username"
            type="text"
            value={instagramUsername}
            onChange={(e) => setInstagramUsername(e.target.value)}
            required
            className="mt-1 mb-4 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Username"
          />
          <button
            onClick={handleSaveInstagramUsername}
            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
          >
            Salva Username
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default LoginPageLayout;












