import React, { useEffect, useState } from 'react';
import gif1 from '../../assets/img/trenitalia.gif';  // Percorso della tua GIF
import gif2 from '../../assets/img/trenitaliarock.gif';  // Percorso della tua GIF
import gif3 from '../../assets/img/trenitaliag.gif';  // Percorso della tua GIF speciale

const LoadingPopup = ({ duration, onEnd }) => {
  const [show, setShow] = useState(true);
  const [gif, setGif] = useState(() => {
    const rand = Math.random();
    if (rand < 0.05) return gif3;      // 5% per gif3
    else if (rand < 0.50) return gif1; // 45% per gif1
    else return gif2;                  // 50% per gif2
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      if (onEnd) {
        onEnd(); // Chiamata alla fine del tempo
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onEnd]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="p-4 bg-white rounded-lg shadow-lg text-center">
        <img src={gif} alt="Caricamento" className="mb-4 w-full md:w-1/2 mx-auto" /> {/* Ingrandita ulteriormente per mobile e desktop */}
        <p className="text-lg font-semibold">Attendere, i treni sono pronti...</p>
      </div>
    </div>
  );
};

export default LoadingPopup;





