// src/components/RegisterPageLayout.jsx

import React, { useState } from 'react';
import useRegisterLogic from '../logic/RegisterLogic';
import ngllogo from '../../assets/img/ngllogo.jpg';

const RegisterPageLayout = () => {
  const { handleEmailPasswordRegister, error } = useRegisterLogic();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-500 to-teal-600 animate-fade-in">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg">
        <div className="flex justify-center">
          <img
            src={ngllogo}
            alt="Logo"
            className="w-16 h-16 rounded-full shadow-lg animate-fade-in"
          />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 animate-slide-down">
          Crea il tuo account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 animate-fade-in">
          Inserisci i tuoi dati per registrarti
        </p>
        <form className="mt-8 space-y-6" onSubmit={handleEmailPasswordRegister}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Username"
              />
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          {error && (
            <div className="text-sm text-red-600 mt-2">{error}</div>
          )}
          <div>
            <button
              type="submit"
              className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            >
              Registrati
            </button>
          </div>
          <div className="text-center mt-6">
            <a
              href="/"
              className="font-medium text-green-600 hover:text-green-500 animate-fade-in"
            >
              Hai già un account? Accedi
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPageLayout;






