import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import PaymentConfirmation from "../components/layout/PagamentoEffettuatoLayout";

function PaymentSucceful() {
    return (
        <div>
            <Navbar />
            <PaymentConfirmation />
            <Footer />
        </div>
    );
}

export default PaymentSucceful;