import React from "react";
import Casabianca from '../assets/img/wh.jpg';
import render from '../assets/img/0380.avif';
import interni from '../assets/img/download.jpg';
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import '../assets/css/casabianca.css';

function CasaBianca() {
  return (
    <div>
    
      <Navbar />
      <div className="casabianca-page">
      <h1 className="casabianca-title">NGLPop</h1>
      <div className="casabianca-content">
        <p className="casabianca-date">17 Luglio 2024 * 12:10 Curiosità e Cultura</p>
        <div className="casabianca-article">
          <h1 className="casabianca-header">LA CASA BIANCA: UN’ICONA DI ELEGANZA E POTERE</h1>
          <p className="casabianca-text">
            La Casa Bianca è famosa in tutto il mondo non solo per la sua <strong>grandezza</strong>, ma anche perché è la <strong>residenza ufficiale del Presidente degli Stati Uniti</strong>. Situata al <strong>1600 di Pennsylvania Avenue a Washington D.C.</strong>, è stata la casa di ogni presidente americano a partire da <strong>John Adams nel 1800</strong>.
          </p>
          <div className="casabianca-row">
            <div className="casabianca-col">
              <img src={Casabianca} alt="Casa Bianca" className="casabianca-image" />
              <p className="casabianca-caption">Foto della Casa Bianca. Credits: Niiprogetti.it</p>
            </div>
          </div>
          <h1 className="casabianca-header">DA QUANTE STANZE E' COMPOSTA, E QUALI SONO I LORO RUOLI?</h1>
          <p className="casabianca-text">
            La Casa Bianca, con i suoi 6 piani e una superficie di circa 5000 m², è un simbolo di potere e storia. Questo edificio maestoso ospita 132 stanze, 147 finestre, 35 bagni, 8 scale e 3 ascensori, e si erge per un'altezza di 21,33 metri.
          </p>
          <h2 className="casabianca-subheader">Il Corpo Centrale</h2>
          <p className="casabianca-text"><strong>Secondo Piano</strong>: Qui si trovano le stanze private del presidente e della sua famiglia, offrendo un rifugio tranquillo e confortevole lontano dagli impegni ufficiali.</p>
          <p className="casabianca-text"><strong>Primo Piano</strong>: Questo piano è ricco di stanze storiche e significative:</p>
          <ul className="casabianca-list">
            <li><strong>Stanza Verde</strong>: Originariamente utilizzata come sala da tè, questa stanza è decorata con un elegante tema verde.</li>
            <li><strong>Stanza Rossa</strong>: Utilizzata per ricevimenti ufficiali, è famosa per il suo arredamento rosso intenso.</li>
            <li><strong>Stanza Blu</strong>: Conosciuta per ospitare l'albero di Natale ogni anno, questa stanza è un punto focale delle festività.</li>
          </ul>
          <h2 className="casabianca-subheader">Le Ali Laterali</h2>
          <p className="casabianca-text"><strong>Ala Est</strong>: Questa sezione della Casa Bianca è il punto di partenza per le visite guidate e ospita vari eventi pubblici.</p>
          <p className="casabianca-text"><strong>Ala Ovest</strong>: Qui si trova l'ufficio del presidente, il cuore delle operazioni esecutive.</p>
          <h2 className="casabianca-subheader">Caratteristiche Speciali</h2>
          <p className="casabianca-text"><strong>Vetri Antiproiettile</strong>: La sicurezza è una priorità, con vetri progettati per resistere agli attacchi. Ogni presidente ha la libertà di decorare la Casa Bianca secondo il proprio gusto personale.</p>
          <p className="casabianca-text"><strong>Situation Room</strong>: Questa stanza è cruciale per la sicurezza nazionale, dove il presidente e i suoi ministri prendono decisioni vitali.</p>
          <h2 className="casabianca-subheader">Aree di Svago</h2>
          <p className="casabianca-text"><strong>Cinema</strong>: Un luogo per rilassarsi e godersi film privati.</p>
          <p className="casabianca-text"><strong>Piscina</strong>: Perfetta per l'esercizio e il relax.</p>
          <p className="casabianca-text"><strong>Biblioteca</strong>: Un rifugio tranquillo per la lettura e lo studio.</p>
          <div className="casabianca-row">
            <div className="casabianca-col">
              <img src={render} alt="Interno della Casa Bianca" className="casabianca-image" />
              <p className="casabianca-caption">Render dell'interno della Casa Bianca. Fonti: Geopop</p>
            </div>
          </div>
          <h1 className="casabianca-header">Sicurezza e Accesso alla Casa Bianca</h1>
          <p className="casabianca-text">
            La Casa Bianca si trova in una zona di volo altamente controllata, con lanciatori di missili terra-aria pronti a intervenire in caso di minacce. Il perimetro è sorvegliato da telecamere a infrarossi e circondato da una recinzione alta 3,3 metri, dotata di sensori di pressione che avvertono la sicurezza se qualcuno tenta di scavalcare. Le finestre sono antiproiettile e la Situation Room è progettata per resistere anche a un attacco nucleare.
          </p>
          <p className="casabianca-text">
            Nonostante le misure di sicurezza, la Casa Bianca è anche un museo visitato ogni giorno da migliaia di persone. Per garantire la sicurezza di tutti, è necessario prenotare la visita con almeno 21 giorni di anticipo, permettendo alle autorità di verificare i documenti di identità e i passaporti di ogni visitatore.
          </p>
          <div className="casabianca-row">
            <div className="casabianca-col">
              <img src={interni} alt="Interno" className="casabianca-image" />
            </div>
          </div>
        </div>
        <h3 className="casabianca-fonti">Fonti: Geopop, Wikipedia e Niiprogetti</h3>
        </div>
        </div>
        <Footer />
    </div>
  );
}

export default CasaBianca;
