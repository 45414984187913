import React, { useState } from 'react';
import { 
  FaHome, 
  FaNewspaper, 
  FaPhotoVideo, 
  FaVideo,  
  FaTrain, 
  FaCalendarAlt, 
  FaGlobe, 
  FaShoppingCart, 
  FaDownload, 
  FaBuilding, 
  FaGamepad, 
  FaSearch 
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/transitions.css';
import autoguidovieLogo from '../../assets/img/autoguidovielogo.png';

// Componente personalizzato per l'immagine come icona,
// che accetta le stesse props (es. className) delle altre icone.
const AutoguidovieIcon = (props) => (
  <img src={autoguidovieLogo} alt="Autoguidovie Logo" {...props} />
);

const AltroSection = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleClick = (path) => {
    window.scrollTo(0, 0); // Scroll to top
    navigate(path);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Definiamo una classe che garantisca la stessa dimensione per tutte le icone
  // Qui usiamo h-16 e w-16 per ingrandirle.
  const iconClass = "h-16 w-16 mb-4";

  const sections = [
    { name: 'Ritorna alla Home', path: '/home', icon: FaHome, description: 'Torna alla pagina principale.' },
    { name: 'News', path: '/news', icon: FaNewspaper, description: 'Leggi le ultime notizie.' },
    { name: 'Galleria', path: '/archiviofoto', icon: FaPhotoVideo, description: 'Esplora la nostra galleria fotografica.' },
    { name: 'Video', path: '/video', icon: FaVideo, description: 'Guarda i nostri video.' },
    { name: 'Orario Linee Autoguidovie', path: '/orarioautoguidovie', icon: AutoguidovieIcon, description: 'Consulta gli orari delle linee Autoguidovie.' },
    { name: 'Scopri il tuo Treno', path: '/scopriltuotreno', icon: FaTrain, description: 'Trova informazioni sui treni.' },
    { name: 'Eventi', path: '/eventi', icon: FaCalendarAlt, description: 'Scopri gli eventi in programma.' },
    { name: 'NGL Pop', path: '/ngl-pop', icon: FaGlobe, description: 'Esplora il nostro mondo.' },
    { name: 'Shop', path: '/shop', icon: FaShoppingCart, description: 'Visita il nostro negozio.' },
    { name: 'Scarica la nostra App', path: '/downloadapp', icon: FaDownload, description: 'Scarica la nostra app.' },
    { name: 'Cila Studios (non disponibile)', path: '#', icon: FaBuilding, description: 'Scopri i nostri studi.' },
    { name: 'Minigioco', path: '/minigioco', icon: FaGamepad, description: 'Gioca al nostro minigioco.' },
  ];

  const filteredSections = sections.filter((section) =>
    section.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-8">Menu</h2>
      
      {/* Input di ricerca con icona */}
      <div className="relative mb-4">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Cerca una sezione..."
          className="w-full p-2 border border-gray-300 rounded"
        />
        <FaSearch className="absolute right-4 top-4 text-gray-400" aria-hidden="true" />
      </div>
      
      {/* Sezioni del menu */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredSections.map((section) => (
          <div
            key={section.name}
            onClick={() => handleClick(section.path)}
            className="flex flex-col items-center p-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 cursor-pointer"
          >
            {/* Utilizzo della stessa classe per tutte le icone */}
            <section.icon className={iconClass} aria-hidden="true" />
            <h3 className="text-xl font-semibold">{section.name}</h3>
            <p className="mt-2 text-center">{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AltroSection;






