import React, {useEffect} from "react";
import Navbar from "../components/layout/Navbar";
import ClassificaBellezzaPageLayout from "../components/layout/ClassificaBellezzaLayout";
import Footer from "../components/layout/Footer";

function ClassificaBellezzaPage() {
     useEffect(() => {
            document.title = 'Classifica Bellezza'; // Imposta il titolo della pagina
          }, []);
    return (
        <>
            <Navbar />
            <ClassificaBellezzaPageLayout />
            <Footer />
        </>
    );
}

export default ClassificaBellezzaPage;