import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getAuth, GoogleAuthProvider, RecaptchaVerifier, PhoneAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDnl8ZvUpEyjClgwYSb5APcU24zY-X0H8U",
  authDomain: "ngl-cila.firebaseapp.com",
  databaseURL: "https://ngl-cila-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "ngl-cila",
  storageBucket: "ngl-cila.appspot.com",
  messagingSenderId: "1049554300483",
  appId: "1:1049554300483:web:a023e58e8c5a8969cc6ef6",
  measurementId: "G-54W482ES88"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const functions = getFunctions(app);
const auth = getAuth(app);
auth.languageCode = 'it'; // Imposta la lingua in base alla preferenza dell'utente

const storage = getStorage(app);

const provider = new GoogleAuthProvider();

export { db, functions, auth, provider, storage, RecaptchaVerifier, PhoneAuthProvider };











