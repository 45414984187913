import React from "react";
import Navbar from "../components/layout/Navbar";
import GameComponent from "../components/layout/Quiz";
import Footer from "../components/layout/Footer";

function QuizNGL() {
    return <div>
        <Navbar />
        <GameComponent />
        <Footer />
    </div>;
}

export default QuizNGL;