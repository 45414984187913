import React from "react";
import { FaInstagram, FaWhatsapp, FaTiktok, FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-black text-white pt-10 pb-6 border-t border-gray-800 font-sans w-full" style={{fontFamily: 'Inter, sans-serif'}}>
      <div className="container mx-auto px-4">
        {/* Sezione superiore: Branding & Navigazione */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h2 className="text-2xl font-bold">NGL Cila</h2>
            <p className="text-sm">
              Copyright © 2025, tutti i diritti riservati
            </p>
          </div>
          <nav>
            <ul className="flex space-x-8">
              <li>
                <a
                  href="/chisiamo"
                  className="text-base hover:text-teal-400 transition duration-200 ease-in-out"
                >
                  Chi siamo
                </a>
              </li>
              <li>
                <a
                  href="/gdpr"
                  className="text-base hover:text-teal-400 transition duration-200 ease-in-out"
                >
                  GDPR
                </a>
              </li>
              <li>
                <a
                  href="mailto:info@nglcila.com"
                  className="text-base hover:text-teal-400 transition duration-200 ease-in-out"
                >
                  Contatti
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {/* Sezione icone social */}
        <div className="flex justify-center space-x-6">
          <a
            href="https://instagram.com/ngl_cila"
            title="Instagram"
            className="p-2 border border-transparent hover:border-teal-400 transition duration-200 rounded-full"
          >
            <FaInstagram size={28} />
          </a>
          <a
            href="https://whatsapp.com/channel/0029VajF80s6BIEcButOf50E"
            title="Whatsapp"
            className="p-2 border border-transparent hover:border-teal-400 transition duration-200 rounded-full"
          >
            <FaWhatsapp size={28} />
          </a>
          <a
            href="https://www.tiktok.com/@ngl_cila?_t=8pip3VNfjG4&_r=1"
            title="TikTok"
            className="p-2 border border-transparent hover:border-teal-400 transition duration-200 rounded-full"
          >
            <FaTiktok size={28} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCM73zq2L4LQMq2To2F7ox3w"
            title="YouTube"
            className="p-2 border border-transparent hover:border-teal-400 transition duration-200 rounded-full"
          >
            <FaYoutube size={28} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;


