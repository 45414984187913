// ../logic/RegisterLogic.js

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set, get, child } from 'firebase/database';

const useRegisterLogic = () => {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const { username, email, password, confirmPassword } = e.target.elements;

    if (password.value !== confirmPassword.value) {
      alert("Le password non coincidono");
      return;
    }

    try {
      // Verifica se l'username è unico
      const isUnique = await isUsernameUnique(username.value);
      if (!isUnique) {
        alert("Questo username è già in uso. Per favore, scegline un altro.");
        return;
      }

      // Crea l'utente con email e password
      const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
      const user = userCredential.user;

      // Salva i dettagli dell'utente nel database
      const registrationDate = new Date().toISOString();
      const userAgent = navigator.userAgent;
      const ip = await fetch('https://api.ipify.org?format=json')
        .then(res => res.json())
        .then(data => data.ip);

      await set(ref(db, 'users/' + user.uid), {
        username: username.value,
        uid: user.uid,
        email: email.value,
        ip,
        registrationDate,
        userAgent,
      });

      // Salva l'username in un nodo separato per garantirne l'unicità
      await set(ref(db, 'usernames/' + username.value), user.uid);

      alert("Registrazione completata con successo!");
      navigate('/');
    } catch (error) {
      console.error("Errore durante la registrazione: ", error);
      alert("Errore durante la registrazione. Riprova.");
    }
  };

  // Funzione per verificare se l'username è unico
  const isUsernameUnique = async (username) => {
    try {
      const usernameRef = ref(db, 'usernames/' + username);
      const snapshot = await get(usernameRef);
      return !snapshot.exists(); // Ritorna true se l'username non esiste
    } catch (err) {
      console.error('Errore durante la verifica dell\'unicità dell\'username:', err);
      return false; // In caso di errore, assume che l'username non sia unico
    }
  };

  return { handleRegister };
};

export default useRegisterLogic;











